<template>
  <v-app>
    <v-container class="pa-0 mt-n5 pa-sm-3 pa-md-3 pb-0">
      <v-alert v-if="alert" dense outlined type="error"
        style="position: fixed; top: 8 ; right: 0 ; z-index: 3 ; background-color: red">
        <strong>{{ msg }}</strong>
      </v-alert>
      <!-- desktop -->
      <div class=" pa-6 pa-sm-4 pa-md-4 pb-0  d-none d-md-block">
        <!-- {{ badgearr }}
        {{ familyaccarray }} -->
        <v-col cols="12" class="pl-7">
          <v-card class="mt-7 elevation-0">
            <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
              <!-- {{ colors[0] }} -->
              <v-list class="" style="background-color: transparent;">
                <v-list-item class=" pt-0 px-0">
                  <v-list-item-content class=" pt-0">
                    <v-list-item-title class="text-start"
                                        style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                        Holdings</v-list-item-title>
                    <v-list-item-subtitle class="text-start pt-1"
                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Stocks held by you in your
                      various accounts
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-list class="mr-7 pt-0" style="background-color: transparent;">
                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                  <v-list-item-content class="pl-2 pa-0 ">
                    <v-list-item-title class="text-start"
                      style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                      {{ filteredArraytag.length }}
                      Companies</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-btn @click="download()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                  mdi-content-save</v-icon>
              </v-btn> -->

              <v-menu v-if="desserts.length > 0" bottom offset-y>

                <template v-slot:activator="{ on, attrs }">
                  <v-btn disable="true" class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                    <v-list-item-title @click="valdwl(item.title)">{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-select label="Filled" :items="filterr" @change="filterseg(filterdata_unpl)" dense
              rounded
             
                          solo flat
                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">

                <template v-slot:selection="{ item }">
                  <span class="font-weight-medium body-2">{{ item }}</span>
                </template>
              </v-select>
              <!-- <select v-model="selectedTag" @change="filterArray">
                <option value="All">All</option>
                <option v-for="tag in uniqueTags" :key="tag" :value="tag">{{ tag }}</option>
              </select> -->


              <!-- <v-select color="black" :items="filterr" @change="filterseg(filterdata_unpl)" @input="seldattt"
                v-model="filterdata_unpl" placeholder="filter" dense prepend-inner-icon="mdi-filter-variant" solo rounded
                flat background-color="#F1F3F8" hide-details class="mr-2 mt-n2 fildrop"
                style="max-width: 140px; font-size: 21px">
              </v-select> -->
              <v-text-field style="width:   100px;" v-model="search" class="tophundraedmutual   mt-4 mr-2" height="36px"
                background-color="#F1F3F8" label="Search" dense
              rounded
             
                          solo flat>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>

            </v-toolbar>
            <v-divider class="mb-3"></v-divider>
            <div class="display-flex row  pa-8">
              <v-row>
                <v-col>
                  <v-card class=" pl-3 py-1 pr-2 " outlined height="90px" width="">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Total investment</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card  class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p v-if="headerval.total_invested && filteredArraytag.length > 0" class="mb-0"
                          style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (headerval.total_invested).toFixed(2) }}</p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Current Value</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p v-if="headerval.holdings_value_buyprice && filteredArraytag.length > 0" class="mb-0"
                          style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (headerval.holdings_value_buyprice).toFixed(2) }} </p>

                        <!-- | {{ headerval.holdings_value_no_buyprice }} -->
                      </v-card>
                      <v-card class="py-1 px-2 elevation-0 ml-1 d-flex" color="#F1F3F8 ">
                        <p v-if="headerval.holdings_value_no_buyprice && filteredArraytag.length > 0" class="mb-0 "
                          style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (headerval.holdings_value_no_buyprice).toFixed(2) }} </p>
                        <v-tooltip color="black" style="background-color:" v-if="headerval.holdings_value_no_buyprice"
                          bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon style="cursor: pointer" class="pt-n4" size="14" color="black" v-bind="attrs"
                              v-on="on">
                              mdi mdi-information-variant
                            </v-icon>
                          </template>
                          <span>Without Buy price value</span>
                        </v-tooltip>

                        <!-- | {{ headerval.holdings_value_no_buyprice }} -->
                      </v-card>

                    </div>
                  </v-card>
                </v-col>
                <!-- <v-col>
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      Without buy price</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0"
                          style="color: #666;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col> -->
                <v-col>
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card v-if="headerval.total_pnl > 0 && filteredArraytag.length > 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(238, 255, 237);">
                        <p class="mb-0"
                          style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (headerval.total_pnl).toFixed(2) }}</p>
                      </v-card>
                      <v-card v-else-if="headerval.total_pnl < 0 && filteredArraytag.length > 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(255, 238, 238);">
                        <p class="mb-0"
                          style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ (headerval.total_pnl).toFixed(2) }}</p>
                      </v-card>
                      <v-card v-if="headerval.pnl_perc && headerval.pnl_perc > 0 && filteredArraytag.length > 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(238, 255, 237);">
                        <p class="mb-0"
                          style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          ({{
                            headerval.pnl_perc }}%)</p>
                      </v-card>
                      <v-card v-if="headerval.pnl_perc && headerval.pnl_perc < 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(255, 238, 238);">
                        <p class="mb-0"
                          style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          ({{
                            headerval.pnl_perc }}%)</p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <!-- <v-col>
                  <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px" width="">
                    <p class="pt-1 mb-3"
                      style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                      ToDay's P&L</p>
                    <v-divider class="mt-2" style="border:solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card v-if="headerval.today_pnl > 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(238, 255, 237);">
                        <p class="mb-0"
                          style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ headerval.today_pnl }}</p>
                      </v-card>
                      <v-card v-else-if="headerval.today_pnl < 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(255, 238, 238);">
                        <p class="mb-0"
                          style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          {{ headerval.today_pnl }}</p>
                      </v-card>
                      <v-card v-if="headerval.today_pnl_perc > 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(238, 255, 237);">
                        <p class="mb-0"
                          style="color: rgb(52, 168, 83);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          ({{
                            headerval.today_pnl_perc }}%)</p>
                      </v-card>
                      <v-card v-if="headerval.today_pnl_perc < 0" class="py-1 px-2 elevation-0 ml-1"
                        style="background-color: rgb(255, 238, 238);">
                        <p class="mb-0"
                          style="color: rgb(225, 38, 38);font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                          ({{
                            headerval.today_pnl_perc }}%)</p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col> -->
              </v-row>
            </div>
            <v-dialog persistent v-model="dialog" width="500px" height="">
              <v-alert v-if="alerttag" width="300px" type="error"
                style="position: fixed; top:  -5px; right: 0 ; z-index: 3 ">{{ msgtag }}</v-alert>
              <v-alert v-if="alerttagsuccess" width="300px" type="success"
                style="position: fixed; top:  -5px; right: 0 ; z-index: 3 ">{{ msgtag }}</v-alert>
              <v-card class="pa-4 pt-4 ">

                <v-card-text class="pa-0">
                  <!-- <v-text-field v-model="tagval" hide placeholder="Tag" filled rounded dense></v-text-field> -->
                  <div class="d-flex row mx-1 my-2">
                    <v-btn @click="dialog = false" icon 
              rounded
             
                          solo flat class="">
                      <v-icon>mdi mdi-arrow-left-thin</v-icon>
                    </v-btn>
                    <p class="mb-0 pt-1 title black--text ">Tags</p>
                    <v-tooltip color="black" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mt-n6 pl-2" size="16" color="primary" dark v-bind="attrs" v-on="on">
                          mdi mdi-information-outline
                        </v-icon>
                      </template>
                      <span class="caption">Select from the list or Add new one</span>
                    </v-tooltip>

                    <v-text-field style="max-width: 190px;" v-model="searchText" class="tophundraedmutual mx-4 ml-auto"
                      height="36px" background-color="#F1F3F8" @input="searcharray" label="Search tags" dense
              rounded
             
                          solo flat>
                      <template v-slot:prepend-inner>
                        <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                      </template>
                    </v-text-field>
                  </div>
                </v-card-text>
                <v-divider class="mt-n6"></v-divider>
                <!-- {{ outputArray }}
                {{ filteredArray }}  -->
                <v-row class="mx-5">

                  <v-col class="d-flex pa-0 pt-4">
                    <v-text-field :rules="rules" v-model="tagnameadd" class="" label="Add new tag"></v-text-field>

                    <div class="mt-5 mr-5 ml-5">
                      <v-menu class="" v-model="menu" dialog-bottom-transition="105" nudge-left="16"
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="{ backgroundColor: color }"
                            style="width: 30px ; height: 30px; cursor: pointer; border-radius: 30px" v-on="on"></div>
                        </template>
                        <v-card>
                          <div class="color-palette">
                            <div v-for="(color, index) in colors" :key="index" :style="{ backgroundColor: color }"
                              @click="selectColor(color)" class="color-box"></div>
                          </div>
                        </v-card>
                        <div class="selected-color" v-if="selectedColor">
                          Selected Color: <span :style="{ backgroundColor: selectedColor }">{{ selectedColor }}</span>
                        </div>
                      </v-menu>
                    </div>

                    <v-btn :loading="loading4" :disabled="tagnameadd.length < 3" @click="newtagadd()"
                      class="px-3 mt-4 text-capitalize caption" dense
              rounded
             
                          solo flat style="background-color: #F1F3F8" >Add
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card style=" 
  overflow: scroll;" height="100px" color="#F1F3F8" class="rounded-lg elevation-0 mt-4 mx-5 pt-2">




                  <v-col cols="12" v-if="outputArray.length == 0 || filteredArray.length == 0" class="text-center mt-5">
                    <!-- <img alt="" class="shrink" src="../../../assets/nodata.svg" width="20%" height="" /> -->
                    <h5>No tags available please add new one</h5>
                  </v-col>
                  <span v-else>
                    <span v-for="item in filteredArray" :key="item.index">
                      <v-chip :color="item.color" @click="selectfromsearch(item.text)" class="pa-0 ma-2">

                        <p class="mb-0 pa-2 white--text caption">{{ item.text }}</p>
                      </v-chip>

                    </span>
                  </span>



                </v-card>


              </v-card>

            </v-dialog>

            <v-dialog v-model="dialogholdinglittle" transition="dialog-bottom-transition" max-width="600">

              <template v-slot:default="dialogholdinglittle">
                <!-- <v-btn text @click="dialogholdinglittle.value = false">Close</v-btn> -->
                <v-sheet style="height: 300px">
                  <v-card class="elevation-0">
                    <v-list-item-content class=" pt-5 pl-5">
                      <v-list-item-title class="text-start"
                        style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">{{
                          symexpandfam }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="text-start pt-1"
                        style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Detailed Holdings about
                        the Script
                      </v-list-item-subtitle>
                      <v-btn absolute right top icon color="black" @click="dialogholdinglittle.value = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-content>
                    <v-divider></v-divider>
                    <v-data-table height="200px" fixed-header outlined hide-default-footer dense :headers="headersdense"
                      :items="dessertsdense" item-key="SCRIP_SYMBOL" class="elevation-0 ma-3">
                      <template v-slot:[`item.PRICE_PREMIUM`]="{ item }">
                        <span class="font-weight-regular " style="font-size: 13px"> {{ (item.PRICE_PREMIUM).toFixed(2)
                        }}</span>
                      </template>
                      <template v-slot:[`item.QUANTITY`]="{ item }">
                        <span class="font-weight-regular " style="font-size: 13px"> {{ item.QUANTITY }}</span>
                      </template>
                      <template v-slot:[`item.Description`]="{ item }">
                        <span class="font-weight-regular " style="font-size: 13px"> {{ item.Description }}</span>
                      </template>
                      <template v-slot:[`item.PUR_DATE`]="{ item }">
                        <span class="font-weight-regular " style="font-size: 13px"> {{ item.PUR_DATE }}</span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-sheet>
              </template>
            </v-dialog>
            <v-row class="  d-flex justify-space-between">
              <v-col lg="8"><v-tabs v-model="tabs" background-color="transparent" color="basil" >
                  <v-tab>
                    Overview
                  </v-tab>
                  <v-tab>
                  Family
                </v-tab>
                </v-tabs></v-col>
              <v-select v-if="tabs == 0" outlined :items="uniqueTags" @change="filterArray" dense
              rounded
             
                          solo flat v-model="selectedTag" label="Tag Filter"
                 background-color="#F1F3F8" hide-details class="mr-6  fildrop"
                style="max-width: 140px; font-size: 21px">

                <template v-slot:selection="{ item }">
                  <span class="font-weight-medium body-2">{{ item }}</span>
                </template>
              </v-select>
            </v-row>
            <v-tabs-items v-model="tabs" class="mt-">
              <v-tab-item>
                <!-- {{filteredArraytag}} -->
                <v-data-table :loading="loader" v-model="selectedRows" height="466px" :search="search" hide-default-footer
                  mobile-breakpoint fixed-header disable-sort :headers="Overviewheaders" :items="filteredArraytag"
                  :items-per-page="itemperpage" style="border: 1px solid #EFEEF3;" class="elevation-0 rounded-lg ">

                  <template v-slot:item="{ item }">
                    <tr class="" style=" position: relative" @mouseover="selectItem2(item)"
                      @mouseleave="unSelectItem2(item)">

                      <td class="pr-0 pt-2" style="width: 400px">
                        <span class="font-weight-bold subtitle-2 d-flex mb-n4" @click="onRowClick(item)"
                          style="cursor: pointer">
                          <span v-if="item.SCRIP_SYMBOL != ''">
                            {{ item.SCRIP_SYMBOL }}
                          </span>
                          <span v-else-if="item.SCRIP_SYMBOL == ''">
                            {{ item.ISIN }}
                          </span>
                          <v-icon class="ml-1" color="#0037B7">mdi
                            mdi-menu-right</v-icon>
                          <span v-if="item.seg_type == 'Equity'" class="ml-1 mr-1"><v-chip style="cursor: pointer" small
                              color="#039BE5" class="elevation-0" width="40px"><span class=" pa-1  caption white--text">{{
                                item.seg_type
                              }}</span></v-chip></span>
                          <span v-else-if="item.seg_type == 'ETF'" class="ml-1 mr-1"><v-chip style="cursor: pointer" small
                              color="#00ACC1" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                                item.seg_type
                              }}</span></v-chip></span>
                          <span v-else-if="item.seg_type == 'Bonds'" class="ml-1 mr-1"><v-chip style="cursor: pointer"
                              small color="#4DB6AC" class="elevation-0" width="25px"><span
                                class=" pa-1  caption white--text">{{ item.seg_type
                                }}</span></v-chip></span>
                          <span v-else-if="item.seg_type == 'SME'" class="ml-1 mr-1"><v-chip style="cursor: pointer" small
                              color="#7CB342" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                                item.seg_type
                              }}</span></v-chip></span>
                          <span v-else-if="item.seg_type == 'REITS'" class="ml-1 mr-1"><v-chip style="cursor: pointer"
                              small color="#FB8C00" class="elevation-0" width="25px"><span
                                class=" pa-1  caption white--text">{{ item.seg_type
                                }}</span></v-chip></span>
                          <span v-else-if="item.seg_type == 'INVITS'" class="ml-1 mr-1"><v-chip style="cursor: pointer"
                              small color="#FB8C00" class="elevation-0" width="25px"><span
                                class=" pa-1  caption white--text">{{ item.seg_type
                                }}</span></v-chip></span>
                             <span v-else-if="item.seg_type == 'MF'" class="ml-1 mr-1"><v-chip style="cursor: pointer"
                              small color="#FB8C00" class="elevation-0" width="25px"><span
                                class=" pa-1  caption white--text">{{ item.seg_type
                                }}</span></v-chip></span>
                          <span v-else-if="item.seg_type == ''" class="ml-1 mr-1"></span>
                          <!-- @click="buypop(item)" -->
                          <span v-if="item.NET - item.updated_qty != 0">

                            <v-tooltip color="black" top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon dark v-bind="attrs" v-on="on" size="14" color="orange">mdi
                                  mdi-alert</v-icon>
                              </template>
                              <span>Holdings discrepancies here</span>
                            </v-tooltip>

                            <!-- <span class="caption"> {{ item.NET }}</span> -->
                          </span>
                          <!-- @click="buypop(item)" -->
                          <span style="cursor: pointer" v-else-if="item.manual_updt == 'yes'"><v-icon size="14"
                              color="red">mdi
                              mdi-information-outline</v-icon>
                            <span class="caption">

                            </span>
                          </span>

                        </span>
                        <br>
                        <span class=" " v-for="item1 in item.tags" color="red" :key="item1.index">
                          <v-chip small :color="item1.color" close @click:close="delettag(item, item1.text)" chip
                            class="caption ml-2 mb-2 white--text">
                            {{ item1.text }}<span class="ml-1"></span>
                          </v-chip>
                        </span>


                      </td>
                      <td>
                        <div class="postionchangeopenpostion" v-if="item === selectedItem2">
                          <div class="display-flex row">
                            <v-btn icon :ripple="false" @click="poptag(item)" style="background: White"
                              class="pa-0 elevation-0 ml-1" dense small>
                              <v-icon color="black" size="16">mdi mdi-tag-outline</v-icon></v-btn>
                          </div>
                        </div>
                      </td>
                      <td class="text-right">
                        <span class="font-weight-regular  subtitle-2"> {{ item.NET }}</span>
                      </td>

                      <td class="text-right">
                        <span class="font-weight-regular subtitle-2"> {{ (item.buy_price).toFixed(2) }}</span>
                        <p class="font-weight-regular caption grey--text mb-0"> {{ (item.NET * item.buy_price).toFixed(2) }}</p>
                      </td>
                      <td class="text-right">
                        <span v-if="ltpvalarray[item.Token]" class="font-weight-regular subtitle-2"> {{
                          ltpvalarray[item.Token].lp }}</span>
                        <span v-else class="font-weight-regular subtitle-2">{{ item.nav_price }}</span>
                        <p v-if="ltpvalarray[item.Token]" class="font-weight-regular caption grey--text mb-0"> {{ (item.NET * ltpvalarray[item.Token].lp).toFixed(2) }}</p>
                        <p v-else class="font-weight-regular caption grey--text mb-0"> {{ (item.NET * item.nav_price).toFixed(2) }}</p>
                      </td>

                      <!-- <td class="text-right">
                        <span v-if="item.pnl_ltp" class="font-weight-regular subtitle-2"> {{ (item.pnl_ltp).toFixed(2) }}</span>
                        <span v-else class="font-weight-regular subtitle-2"> 0</span>
                      </td>

                      <td class="text-right">
                        <span v-if="item.pnl_per_ltp" class="font-weight-regular subtitle-2"> {{ (item.pnl_per_ltp).toFixed(2) }}</span>
                        <span v-else class="font-weight-regular subtitle-2">0</span>
                      </td> -->

                      <td class="text-right">
                        <span>
                          <span v-if="(ltpvalarray[item.Token]) && item.buy_price != 0"
                            class="font-weight-regular subtitle-2">
                            {{ ((parseFloat(ltpvalarray[item.Token].lp) - parseFloat(item.buy_price)) *
                              item.NET).toFixed(2) }}
                          </span>
                          <span v-else-if="item.buy_price == 0" class="font-weight-regular subtitle-2" style="color: black">0
                        </span>
                          <span v-else class="font-weight-regular subtitle-2" style="color: black"> {{ ((item.nav_price  - parseFloat(item.buy_price)) *
                              item.NET).toFixed(2) }}</span>
                        </span>

                      </td>
                      <!-- {{ ltpvalarray[item.Token] }} -->
                      <td class="pr-8 text-right ">
                        <span v-if="item.buy_price != 0"
                          :style="{ color: calculateColor(item) }">
                      {{ calculatePercentage(item) }}%
                    </span>

                        <span v-else class="font-weight-regular subtitle-2" style="color: black">
                          0%</span>

                      </td>
                      <!-- <td class="pr-0 pl-">
                                            <span class="font-weight-regular subtitle-2"> {{ item.present }}</span>

                                        </td> -->

                    </tr>
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-16">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">No data available</p>
                    </v-col>
                  </template>
                  <!-- <template v-slot:footer>
                <v-divider></v-divider>
                <div class="text-center" v-if="more">
                  <v-btn text color="primary" dark class="ma-2 text-none" @click="morebtn()">
                    <span class=""
                      style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                      more
                      Companies</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
                <div class="text-center" v-if="less">
                  <v-btn text color="primary" dark class="ma-2 text-none" @click="lessbtn()">
                    <span class=""
                      style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                      Show Less Companies</span> <v-icon color="black"> mdi-chevron-up</v-icon>
                  </v-btn>
                </div>
              </template> -->
                </v-data-table>
              </v-tab-item>
              <v-tab-item>

                <v-data-table :loading="loader1" fixed-header disable-sort
                  style="border: 1px solid #EFEEF3; cursor: pointer" hide-default-footer height="466px"
                  :items-per-page="itemperpageinner" :headers="dessertHeaders" :items="dessertstabs"
                  :single-expand="singleExpand" :expanded.sync="expanded" item-key="SCRIP_SYMBOL" show-expand
                  class="elevation-1" @click:row="toggleExpanded">
                  <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                    <span class="font-weight-bold " style="font-size: 14px; width: 10px"> {{ item.SCRIP_SYMBOL }}</span>
                  </template>

                  <template v-slot:[`item.NET`]="{ item }">
                    <span class="font-weight-regular " style="font-size: 13px"> {{ item.NET }}</span>
                  </template>
                  <template v-slot:[`item.SCRIP_VALUE`]="{ item }">
                    <span class="font-weight-regular " style="font-size: 13px"> {{ (item.SCRIP_VALUE).toFixed(2) }}</span>
                  </template>
                  <template v-slot:[`item.last_close`]="{ item }">
                    <span class="font-weight-regular " style="font-size: 13px"> {{ item.last_close }}</span>
                  </template>
                  <template v-slot:[`item.pnl`]="{ item }">
                    <span v-if="item.pnl > 0" class="font-weight-regular subtitle-2" style="color: green"> {{ item.pnl
                    }}</span>
                    <span v-if="item.pnl < 0" class="font-weight-regular subtitle-2" style="color: red"> {{ item.pnl
                    }}</span>
                    <span v-if="item.pnl == 0" class="font-weight-regular subtitle-2" style="color: black"> {{
                      item.pnl
                    }}</span>
                  </template>
                  <template v-slot:[`item.pnl_perc`]="{ item }">
                    <!-- <span class="font-weight-regular " style="font-size: 13px"> {{ item.pnl_perc }}</span> -->
                    <span v-if="item.pnl_perc > 0" class="font-weight-regular subtitle-2" style="color: green"> {{
                      item.pnl_perc
                    }}</span>
                    <span v-if="item.pnl_perc < 0" class="font-weight-regular subtitle-2" style="color: red"> {{
                      item.pnl_perc
                    }}</span>
                    <span v-if="item.pnl_perc == 0" class="font-weight-regular subtitle-2" style="color: black"> {{
                      item.pnl_perc
                    }}</span>
                  </template>


                  <template v-slot:expanded-item="{ headers, item }">
                    <td class="px-0 pl-1 mt" style="background-color: #D9D9D9" :colspan="headers.length">
                      <!-- {{item.famacc[0]}} -->
                      <v-data-table hide-default-header disable-sort fixed-header style="border: 1px solid #EFEEF3;"
                        hide-default-footer :items-per-page="itemperpageinner" :headers="dessertHeadersfam"
                        :items="item.famacc[0]" item-key="SCRIP_SYMBOL" class="elevation-1">

                        <!-- 
                           <template  v-slot:[item.cc]="{ item }" >
                            <span  class="font-weight-medium "  style="font-size: 14px ;"> {{ item.cc }}</span>
                          </template>
                          <template  v-slot:[item.NET]="{ item }"  >
                            <span class="font-weight-regular text-right"   style="align:left;font-size: 13px; margin-left: 12px"> {{ item.NET
                            }}</span>
                          </template>
                          <template v-slot:[item.buy_price]="{ item }">
                            <span class="font-weight-regular text-right" style="font-size: 13px"> {{ item.buy_price }}</span>
                          </template>
                          <template v-slot:[item.last_close]="{ item }">
                            <span class="font-weight-regular text-right" style="font-size: 13px"> {{ item.last_close }}</span>
                          </template>
                          <template v-slot:[item.pnl]="{ item }">
                            <span class="font-weight-regular text-right" style="font-size: 13px"> {{ item.pnl }}</span>
                          </template>
                          <template v-slot:[item.pnl_perc]="{ item }">
                            <span class="font-weight-regular text-left" style="font-size: 13px"> {{ item.pnl_perc }}</span>
                          </template> -->






                        <template v-slot:item="{ item }">
                          <tr class="" style=" position: relative">

                            <td class="" width="14%">
                              <span class="font-weight-medium " style="font-size: 14px ;"> {{ item.cc }}</span>
                            </td>
                            <td width="4%"></td>

                            <td class="pr-2 text-right" width="14%" style="">
                              <span class="font-weight-regular " style="font-size: 13px; margin-left: 12px"> {{
                                item.NET }}</span>

                            </td>
                            <td class=" text-right" style="" width="10%">
                              <span class="font-weight-regular" style="font-size: 13px"> {{ item.buy_price }}</span>
                            </td>
                            <td class="pr-0 text-right" style="" width="9%">
                              <span class="pl-0 ml-0 font-weight-regular " style="font-size: 13px"> {{ item.last_close
                              }}</span>
                            </td>

                            <td class="pr-1 text-right" width="10%">
                              <span class="font-weight-regular " style="font-size: 13px"> {{ item.pnl }}</span>
                            </td>

                            <td class=" pr-1 text-right mr-4" style="" width="10%">
                              <span class="font-weight-regular" style="font-size: 13px"> {{ (item.pnl_perc).toFixed(2)
                              }}</span>
                            </td>
                            <td width="5%"></td>

                          </tr>
                        </template>



                        <!-- <template  v-slot:[item.cc]="{ item }" >
                            <span  class="font-weight-medium " style="font-size: 14px ;"> {{ item.cc }}</span>
                          </template>
                          <template  v-slot:[item.NET]="{ item }"  >
                            <span class="font-weight-regular " style="font-size: 13px; margin-left: 12px"> {{ item.NET
                            }}</span>
                          </template>
                          <template v-slot:[item.buy_price]="{ item }">
                            <span class="font-weight-regular " style="font-size: 13px"> {{ item.buy_price }}</span>
                          </template>
                          <template v-slot:[item.last_close]="{ item }">
                            <span class="font-weight-regular " style="font-size: 13px"> {{ item.last_close }}</span>
                          </template>
                          <template v-slot:[item.pnl]="{ item }">
                            <span class="font-weight-regular " style="font-size: 13px"> {{ item.pnl }}</span>
                          </template>
                          <template v-slot:[item.pnl_perc]="{ item }">
                            <span class="font-weight-regular " style="font-size: 13px"> {{ item.pnl_perc }}</span>
                          </template> -->
                      </v-data-table>
                    </td>

                  </template>
                  <template v-slot:[`item.famacc`]="{ item }">
                     
                    <div v-if="item.famacc[0].length < 5" class="d-flex row">
                      <v-card v-for="(data, index) in item.famacc[0]"
                        :key="index"
                        :style="{ backgroundColor: colorsbadge[index], marginLeft: '-8px', width: '18px', height: '18px', borderRadius: '30px' }">
                        <span class="font-weight-bold white--text"
                          style="font-size: 12px; padding-left: 5px; padding-bottom: 4px">
                          {{ data.cc[0] }}
                        </span>
                      </v-card>
                    </div>
                    <div v-else class="d-flex row">
                      <v-card
                        v-for="(data, index) in item.famacc[0].slice(0, 5)"
                        :key="index"
                        :style="{ backgroundColor: colorsbadge[index], marginLeft: '-8px', width: '18px', height: '18px', borderRadius: '30px' }">
                        <span class="font-weight-bold white--text"
                          style="font-size: 12px; padding-left: 5px; padding-bottom: 4px">
                          {{ data.cc[0] }}
                        </span>
                      </v-card>
                      <v-card
                        :style="{ backgroundColor: colorsbadge[index], marginLeft: '-8px', width: '18px', height: '18px', borderRadius: '30px' }">
                        <span class="font-weight-bold white--text"
                          style="font-size: 12px; padding-left: 5px; padding-bottom: 4px">
                          +
                        </span>
                      </v-card>
                    </div>


                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-16">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">Your account not attach with any other account. </p>
                      <a target="_blank" :href="`https://profile.mynt.in/family?uid=${actid}&token=${susertoken}`"  class="font-weight-bold caption blue--text">Click here to add account....</a>
                    </v-col>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>

          </v-card>

          <v-dialog v-model="dialogbuy" width="700">


            <v-card height="" class="pa-5">
              <v-card-title>Holdings discrepancies</v-card-title>

              <v-divider></v-divider>
              <div class="d-flex pa-3 mx-4 pa-0">
                <p class=" mb-0 body-2 mt-2 mr-8">Total Quantity : {{ qntdep }}</p>
                <p v-if="maxquantity > 0" class=" mb-0 body-2 ml-auto mt-2 ">Required Quantity : {{ maxquantity }}</p>
                <p v-if="maxquantity == 0" class=" mb-0 body-2 mt-2 ml-auto"></p>
                <p v-if="maxquantity < 0" class=" mb-0 body-2 mt-2 ml-auto">Required Quantity : {{ maxquantity }}<span
                    class="caption" color="red">Need to check</span></p>


              </div>
              <div class="mx-7 ">

                <p class=" body-1 mb-1">Trade</p>
                <v-divider class=""></v-divider>
                <span class="caption pt-1">To fix this discrepancy, you should add manual trades here with matching
                  quantities. Trades added can not be edited/deleted once the price is updated. </span>
              </div>
              <v-data-table v-if="dessertsforbuy.length != 0" hide-default-footer dense :headers="headersforbuy"
                :items="dessertsforbuy" class="elevation-1 mx-6 my-3">
                <template v-slot:[`item.isin`]="{ item }">
                  <span class="font-weight-regular " style="font-size: 13px"> {{ item.isin }}</span>
                  <p class="font-weight-regular caption mb-0"> {{ item.description }}</p>
                </template>
                <template v-slot:[`item.pur_date`]="{ item }">
                  <span class="font-weight-regular caption"> {{ item.pur_date }}</span>
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                  <span class="font-weight-regular caption"> {{ item.qty }}</span>
                </template>
                <template v-slot:[`item.buy_price`]="{ item }">
                  <span class="font-weight-regular caption"> ₹{{ item.buy_price }}</span>
                </template>

              </v-data-table>
              <div v-if="maxquantity > 0" class="d-flex pa-3 mx-4">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">

                    <v-text-field class="mr-2" v-model="datepop" label="Trade Date" prepend-inner-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="datepop" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <v-text-field :max="maxquantity" type="number" class="mr-2" style="margin-top: 22px" v-model="addquantity"
                  label="Quantity" dense v-bind="attrs" v-on="on"></v-text-field>
                <v-text-field type="number" class="mr-2" v-model="addprice" label="Buy Price"
                  prepend-inner-icon="mdi mdi-currency-rupee" hide-details v-bind="attrs" v-on="on"></v-text-field>
                <!-- <v-select style="" dense background-color="#F1F3F8" rounded :items="itemstype" v-model="tradetype" flat
                  solo class="dropdown  elevation-0 rounded-pill  " label="Type" hide-details
                  append-icon="mdi-chevron-down"></v-select> -->

              </div>
              <p v-if="maxquantity > 0" class="text-end mx-7 mb-0"><v-btn @click="addtrade()" text
                  style="background: black" solo dense
                  class="ml-auto body-2 white--text text-capitalize rounded-pill"><v-icon size="20"></v-icon>Add</v-btn>
              </p>

            </v-card>

            <!-- <v-card v-if="addtag" style="width: 1000px" class="pa-5">
              <v-icon @click="addtag = false">mdi mdi-arrow-left-thick</v-icon>

            </v-card> -->

          </v-dialog>
        </v-col>
        <div class="mt-2 mt-sm-0 mt-md-0">
          <div class=" mb-4">
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="d-md-none">
        <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent;">
            <v-list-item class=" pt-0 px-0">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                      fill="#363636" />
                    <path
                      d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                      fill="#E3E3E3" />
                    <path
                      d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                      fill="#363636" />
                  </svg> -->
              <v-list-item-content class=" pt-0">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                  Holdings </v-list-item-title>

                <v-list-item-subtitle class="text-start pt-1"
                  style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Stocks held by you in your
                  various accounts
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-list class=" pt-0" style="background-color: transparent;">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
              <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
              <!-- height="16px" /> -->




            </v-list-item>
            <!-- <v-list-item-content class="pa-0 pt-1">
                  <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                    recommended</v-list-item-title>
                </v-list-item-content> -->
          </v-list>


        </v-toolbar>
        <v-divider class=" mx-2"></v-divider>
        <v-col class="pa-0 px-2" cols="12" md="12">
        </v-col>
        <v-card color="#eeeeee" outlined class=" elevation-0 rounded-lg pa-6 mt-3 mx-2">
          <!-- <div class="text-center pa-4">
            <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
              Total investment</p>
            <p v-if="headerval.total_invested" class="ctnbtl headline mb-0" style="line-height:28px; color: #505359; ">
              {{ (headerval.total_invested).toFixed(2) }}</p>
          </div> -->
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Total investment</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="headerval.total_invested" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #000000;">
                      {{ (headerval.total_invested).toFixed(2) }}
                    
                    </p>
                   
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Current Value</p>
                  </v-col>
                  <v-col cols="7" v-if="headerval.holdings_value_buyprice">

                    <p class="ctnbtl body-1 text-right" style="line-height:28px; color: black; ">
                      {{ (headerval.holdings_value_buyprice).toFixed(2) }}
                      <span class="overline ml-2 pa-1 mt-n1 rounded-lg"
                        style="background-color: #F1F3F8; color: black; ">{{
                          (headerval.holdings_value_no_buyprice).toFixed(2) }}
                        <v-tooltip color="black" style="background-color:" v-if="headerval.holdings_value_no_buyprice"
                          bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon style="cursor: pointer" class="" size="14" color="black" v-bind="attrs" v-on="on">
                              mdi mdi-information-variant
                            </v-icon>
                          </template>
                          <span>Without Buy price value</span>
                        </v-tooltip>
                      </span>
                    </p>



                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Total P&L</p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="headerval.total_pnl < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ (headerval.total_pnl).toFixed(2) }}
                      <span class="overline ml-2 pa-1 rounded-lg" style="background-color: #FFEEEE; color: #E12626; ">{{
                        headerval.pnl_perc }}%</span>
                    </p>
                    <p v-if="headerval.total_pnl > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ (headerval.total_pnl).toFixed(2) }}
                      <span class="overline ml-2 pa-1 rounded-lg" style="background-color: #EEFFED; color: #34A853; ">{{
                        headerval.pnl_perc }}%</span>
                    </p>
                    <p v-if="headerval.total_pnl == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ (headerval.total_pnl).toFixed(2) }}
                      <span class="overline ml-2 pa-1 rounded-lg" style="background-color: #EEFFED; color: #505359; ">{{
                        headerval.pnl_perc }}%</span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      ToDay's P&L</p>
                  </v-col>
                  <v-col cols="7">

                    <p v-if="headerval.today_pnl < 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #E12626; ">
                      {{ headerval.today_pnl }}
                      <span class="overline ml-2 pa-1 rounded-lg" style="background-color: #FFEEEE; color: #E12626; ">{{
                        headerval.today_pnl_perc }}%</span>
                    </p>
                    <p v-if="headerval.today_pnl > 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #34A853; ">
                      {{ headerval.today_pnl }}
                      <span class="overline ml-2 pa-1 rounded-lg" style="background-color: #EEFFED; color: #34A853; ">{{
                        headerval.today_pnl_perc }}%</span>
                    </p>
                    <p v-if="headerval.today_pnl == 0" class="ctnbtl body-1 text-right"
                      style="line-height:28px; color: #505359; ">
                      {{ headerval.today_pnl }}
                      <span class="overline ml-2 pa-1 rounded-lg" style="background-color: #EEFFED; color: #505359; ">{{
                        headerval.today_pnl_perc }}%</span>
                    </p>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </v-card>
        </v-card>
        <v-divider class="mt-4 mb-2 "></v-divider>
        <div class="d-flex row mb-2">
          <p class="text-start mb-0   mt-6 ml-2 px-4 "
            style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
            No of holdings
            <span class="  rounded-lg" style=" color: #000000;">({{ desserts.length }})</span>
          </p>
          <v-btn v-if="desserts.length > 0" @click="sheet = true" class="ml-auto mr-6 pt-6" 
              
             
                          solo flat icon> <v-icon>mdi
              mdi-download</v-icon></v-btn>
        </div>
        <div class="text-center">
          <v-bottom-sheet v-model="sheet" inset>

            <v-sheet class="text-center" height="200px">
              <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                <v-icon size="40">mdi mdi-close-circle</v-icon>
              </v-btn> -->
              <!-- {{ radios }}/ -->
              <p class="mb-0 pt-3 title font-weight-medium">Holdings</p>
              <v-card class="elevation-0 ma-8 mt-0">
                <v-radio-group class="mt-0" v-model="radios" mandatory>
                  <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                  <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                </v-radio-group>
                <v-btn @click="valdwl(radios)" class="white--text elevation-0" color="black" block
              rounded
             
                          solo flat >
                  {{
                    radios }}</v-btn>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </div>
        <v-bottom-sheet v-model="sheet" inset>

          <v-sheet class="text-center" height="200px">
            <p class="mb-0 pt-3 title font-weight-medium">Holdings</p>
            <v-card class="elevation-0 ma-8 mt-0">
              <v-radio-group class="mt-0" v-model="radios" mandatory>
                <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
              </v-radio-group>
              <v-btn @click="valdwl(radios)" class="white--text elevation-0" color="black" block >
                {{
                  radios }}</v-btn>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
        <v-divider class="mt-4 mb-2 "></v-divider>

        <v-row class="mx-2 mb-2">
          <v-col>
            <v-select :items="filterr" @change="filterseg(filterdata_unpl)"  v-model="filterdata_unpl"
              placeholder="filter" dense
              rounded
             
                          solo flat  background-color="#F1F3F8" hide-details class="mr-2 mt-2 fildrop"
              style="max-width: 100%; font-size: 21px">

              <template v-slot:selection="{ item }">
                <span class="font-weight-medium body-2">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select :items="uniqueTags" @change="filterArray" dense
              rounded
             
                          solo flat  v-model="selectedTag" label="filter" background-color="#F1F3F8" hide-details class="mr-2 mt-2 fildrop"
              style="max-width: 100%; font-size: 21px">

              <template v-slot:selection="{ item }">
                <span class="font-weight-medium body-2">{{ item }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-divider class=" mb-2 "></v-divider>
        <v-row>
          <v-col cols="12">
            <v-text-field style="width: 90%;" dense v-model="search" class="tophundraedmutual   mt-4 mx-4" height="24px"
              background-color="#F1F3F8" label="Search companies" solo 
              rounded
             
                           flat >
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="16px" />
              </template>
            </v-text-field>
          </v-col>
          <!-- <v-col cols="1">
            <v-icon class="mt-4" @click="filterpopup()">mdi mdi-filter-variant</v-icon>
          </v-col> -->
        </v-row>
        <v-divider class=" mb-2 "></v-divider>
        <v-row class="pt-0">
          <v-col lg="4" sm="12" md="12" cols="12">
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
              <!-- @click="downsum = true" -->
              <v-tab>
                Overview
              </v-tab>
              <!-- downsum = false -->
              <v-tab>
                Family
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <!-- {{ loader }} -->

        <!-- {{ dialog }} -->
        <v-bottom-sheet v-model="bottomholdingsheet">
          <v-sheet style="height: 600px">
            <v-card class="elevation-0">
              <v-list-item-content class=" pt-5 pl-5">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">Detailed
                  Holding
                </v-list-item-title>

                <v-list-item-subtitle class="text-start pt-1"
                  style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Detailed
                  Holdings about
                  the Script
                </v-list-item-subtitle>

              </v-list-item-content>
              <v-divider></v-divider>
              <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3" height="500px"
                style="overflow: scroll">
                <div class="text-center pa-4">
                  <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                    Script</p>

                  <p v-if="symexpandfam != ''" class="ctnbtl headline mb-0"
                    style="line-height:28px; color: rgb(0, 0, 0); ">
                    <!-- {{ symexpandfam }} -->
                    {{ symexpandfam }}
                  </p>

                </div>
                <v-card v-for="item in dessertsdense" :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                  class="elevation-0  px-4 mb-3" width="100%">
                  <span class="d-flex row mt-3 px-4">
                    <p class="pl-1 body-2 font-weight-regular text-start" style="color: #666666;">
                      Date:
                      <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000;">{{
                        item.PUR_DATE
                      }}</span>
                    </p>


                  </span>

                  <v-divider class="mb-2"></v-divider>
                  <v-row class="px-2">
                    <v-col class="pb-0">

                      <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">
                        Quantity:
                        <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000;">{{
                          item.QUANTITY
                        }}</span>
                      </p>

                    </v-col>
                    <v-col class="text-end pb-0">

                      <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">
                        Buy Price <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                          (item.PRICE_PREMIUM).toFixed(2) }}
                        </span>
                      </p>

                    </v-col>
                  </v-row>
                  <v-row class="px-5">
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">
                      Description : <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                        item.Description }}
                      </span>
                    </p>
                  </v-row>
                </v-card>
                <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                  class="elevation-0  px-4" width="100%">
                  <v-row class="px-2">
                    <v-col class="mx-auto text-center my-4">
                      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>

            </v-card>
          </v-sheet>
        </v-bottom-sheet>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
              width="100%">
              <v-row class="px-2" v-if="filteredDesserts.length < 0">
                <v-col class="mx-auto pa-15 ">
                  <img alt="" class="shrink" src="../../../assets/nodata.svg" width="50%" height="" />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
              class="elevation-0  px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>
            <span v-else>
              <v-card v-for="item in filteredDesserts " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                class="elevation-0 pt-2 px-4" width="100%">
                <span class="d-flex">
                  <span @click="onRowClickmobile(item)" class="text-start pa-2"
                    style="color: #000000;font-size: 15px;font-weight:459;">{{ item.SCRIP_NAME
                    }} <v-icon class="ml-1" color="#0037B7">mdi
                      mdi-menu-right</v-icon>
                    <span v-if="item.seg_type == 'Equity'" class="ml-1  "><v-chip @click="onRowClickmobile(item)" small
                        color="#039BE5" class="elevation-0" width="40px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
                    <span v-else-if="item.seg_type == 'ETF'" class="ml-1  "><v-chip @click="onRowClickmobile(item)" small
                        color="#00ACC1" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
                    <span v-else-if="item.seg_type == 'Bonds'" class="ml-1  "><v-chip @click="onRowClickmobile(item)"
                        small color="#4DB6AC" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
                    <span v-else-if="item.seg_type == 'SME'" class="ml-1  "><v-chip @click="onRowClickmobile(item)" small
                        color="#7CB342" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
                    <span v-else-if="item.seg_type == 'REITS'" class="ml-1  "><v-chip @click="onRowClickmobile(item)"
                        small color="#FB8C00" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
                    <span v-else-if="item.seg_type == 'INVITS'" class="ml-1  "><v-chip @click="onRowClickmobile(item)"
                        small color="#FB8C00" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
<span v-else-if="item.seg_type == 'MF'" class="ml-1  "><v-chip @click="onRowClickmobile(item)"
                        small color="#FB8C00" class="elevation-0" width="25px"><span class=" pa-1  caption white--text">{{
                          item.seg_type
                        }}</span></v-chip></span>
                    <span v-else-if="item.seg_type == ''" class="ml-1  "></span>

                  </span>
                  <span style="cursor: pointer" v-if="item.NET - item.updated_qty != 0">

                    <v-tooltip color="black" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mt-3" dark v-bind="attrs" v-on="on" size="14" color="orange">mdi
                          mdi-alert</v-icon>
                      </template>
                      <span>Holdings discrepancies here</span>
                    </v-tooltip>
                    <!-- <span class="caption"> {{ item.NET }}</span> -->
                  </span>

                  <span class="ml-auto mt-2">
                    <v-icon size="16" class="" @click="poptagmobile(item)">mdi mdi-tag-outline</v-icon>
                  </span>
                </span>

                <v-divider class="mb-2 mt-2"></v-divider>
                <v-row class="px-2">
                  <v-col>
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Qty : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.NET }}</span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Avg Price : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ (item.buy_price).toFixed(2)}} 
                        <span
                        class="mb-0 body-2 grey--text font-weight-regular" style="color: #000000;">({{ (item.NET * item.buy_price).toFixed(2) }})</span>
                      </span>
                    </p>
                  </v-col>
                  <v-col class="text-end">
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">P&L :
                      <span>
                          <span v-if="(ltpvalarray[item.Token]) && item.buy_price != 0"
                            class="font-weight-regular subtitle-2">
                            {{ ((parseFloat(ltpvalarray[item.Token].lp) - parseFloat(item.buy_price)) *
                              item.NET).toFixed(2) }}
                          </span>
                          <span v-else-if="item.buy_price == 0" class="font-weight-regular subtitle-2" style="color: black">0
                        </span>
                          <span v-else class="font-weight-regular subtitle-2" style="color: black"> {{ ((item.nav_price  - parseFloat(item.buy_price)) *
                              item.NET).toFixed(2) }}</span>
                        </span>
                        <span v-if="item.buy_price != 0"
                          :style="{ color: calculateColor(item) }">
                      ({{ calculatePercentage(item) }})%
                    </span>
                      <span v-else style="color: rgb(0, 0, 0);">0 </span>
                    </p>
                    <span v-if="ltpvalarray[item.Token]" class="font-weight-regular subtitle-2"> {{
                          ltpvalarray[item.Token].lp }}</span>
                        <span v-else class="font-weight-regular subtitle-2">{{ item.nav_price }}</span>
                        <span v-if="ltpvalarray[item.Token]" class="font-weight-regular caption grey--text mb-0"> ({{ (item.NET * ltpvalarray[item.Token].lp).toFixed(2) }})</span>
                        <span v-else class="font-weight-regular caption grey--text mb-0">( {{ (item.NET * item.nav_price).toFixed(2) }})</span>
                  </v-col>
                </v-row>
                <v-divider v-if="item.tags.length != 0" class="mb-2"></v-divider>
                <span class="" v-for="item1 in item.tags" color="" :key="item1.index">
                  <v-chip small :color="item1.color" close @click:close="delettag(item, item1.text)" chip
                    class="caption ml-2 mb-2 white--text">
                    {{ item1.text }}<span class="ml-1"></span>
                  </v-chip>
                </span>
              </v-card>
            </span>
          </v-tab-item>
          <v-tab-item>
            <!-- {{dessertstabs.length > 0  }} -->
            <span v-if="dessertstabs.length > 0  ">
              <v-card v-for="item in dessertstabs " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                class="elevation-0 pt-2 px-4" width="100%">
                <span class="d-flex" @click="expandmblclick(item)">

                  <span class="text-start pa-2  " style="color: #000000;font-size: 15px;font-weight:459;">{{
                    item.SCRIP_SYMBOL
                  }}
                  </span>
                  <div v-if="item.famacc[0].length < 5" class="d-flex row ml-2 mt-2">
                      <v-card v-for="(data, index) in item.famacc[0]"
                        :key="index"
                        :style="{ backgroundColor: colorsbadge[index], marginLeft: '-8px', width: '18px', height: '18px', borderRadius: '30px' }">
                        <span class="font-weight-bold white--text"
                          style="font-size: 12px; padding-left: 5px; margin-top: -8px">
                          {{ data.cc[0] }}
                        </span>
                      </v-card>
                    </div>
                    <div v-else class="d-flex row ml-2 mt-2">
                      <v-card
                        v-for="(data, index) in item.famacc[0].slice(0, 5)"
                        :key="index"
                        :style="{ backgroundColor: colorsbadge[index], marginLeft: '-8px', width: '18px', height: '18px', borderRadius: '30px' }">
                        <span class="font-weight-bold white--text"
                          style="font-size: 12px; padding-left: 5px; padding-bottom: 4px">
                          {{ data.cc[0] }}
                        </span>
                      </v-card>
                      <v-card
                        :style="{ backgroundColor: colorsbadge[index], marginLeft: '-8px', width: '18px', height: '18px', borderRadius: '30px' }">
                        <span class="font-weight-bold white--text"
                          style="font-size: 12px; padding-left: 5px; padding-bottom: 4px">
                          +
                        </span>
                      </v-card>
                    </div>

                  <!-- v-if="!expandmbl" -->
                  <!-- <v-btn  @click="expandmblclick()">
                  <v-icon  class="ml-auto">mdi mdi-chevron-down</v-icon>
                  <v-icon v-else-if="expandmbl" class="ml-auto">mdi mdi-chevron-up</v-icon>
                </v-btn> -->

                </span>

                <v-divider class="mb-2 mt-2"></v-divider>
                <v-row class="px-2">
                  <v-col>
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Qty : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.NET }}</span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Invested : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SCRIP_VALUE }}</span>
                    </p>
                  </v-col>
                  <v-col class="text-end">
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">P&L : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.pnl }}
                        <span v-if="item.pnl_perc > 0" style="color: green;"> ({{ item.pnl_perc }}%)</span>
                        <span v-else-if="item.pnl_perc < 0" style="color: rgb(128, 0, 0);"> ({{ item.pnl_perc }}%)</span>
                        <span v-else-if="item.pnl_perc == 0" style="color: rgb(0, 0, 0);"> ({{ item.pnl_perc }}%)</span>
                      </span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Close Price : <span
                        class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.last_close }}</span>
                    </p>
                  </v-col>
                </v-row>


              </v-card>
            </span>
            <v-card v-else-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
              class="elevation-0  px-4" width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center my-4">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-else
              style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
              width="100%">
              <v-row class="px-2">
                <v-col class="mx-auto text-center">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="25%" height="" />
                      <p class="caption font-weight-bold black--text">Your account not attach with any other account. </p>
                      <a target="_blank" :href="`https://profile.mynt.in/family?uid=${actid}&token=${susertoken}`"  class="font-weight-bold caption blue--text">Click here to add account....</a>
                    </v-col>
              </v-row>
            </v-card>
           
          

           
            <v-bottom-sheet v-model="sheetfam">
              <v-sheet class="text-center" height="600px">

                <v-list-item-content class=" pt-5 pl-5">
                  <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Fam Script</v-list-item-title>

                  <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Family details about
                    the Script
                  </v-list-item-subtitle>

                </v-list-item-content>
                <v-divider></v-divider>
                <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3" height="100%"
                  style="overflow: scroll">
                  <div class="text-center pa-4">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                      Script</p>

                    <p v-if="symexpandfam != ''" class="ctnbtl headline mb-0"
                      style="line-height:28px; color: rgb(0, 0, 0); ">
                      {{ symexpandfam }}
                    </p>

                  </div>
                  <v-card v-for="item in famexpandarray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4" width="100%">
                    <span class="d-flex row mt-3 mb-3 px-4">
                      <p class="  pb-0 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">{{
                        item.cc }}
                      </p>

                      <p class="mb-0 ml-auto body-2 font-weight-regular" style="color: #000000;">
                        Quantity : {{ item.NET }}</p>
                    </span>

                    <v-divider class="mb-2"></v-divider>
                    <v-row class="px-2">
                      <v-col>

                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">
                          Buy Price:
                          <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000;">{{
                            item.buy_price
                          }}</span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular text-start" style="color: #666666;">
                          Close Price :
                          <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                            item.last_close }}</span>
                        </p>
                      </v-col>
                      <v-col class="text-end">

                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">
                          P&L: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.pnl }}
                          </span>
                        </p>
                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">P&L Change :
                          <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                            item.pnl_perc }}
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                    class="elevation-0  px-4" width="100%">
                    <v-row class="px-2">
                      <v-col class="mx-auto text-center my-4">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-sheet>
            </v-bottom-sheet>
          </v-tab-item>
        </v-tabs-items>

        <v-bottom-sheet height="1000px" v-model="sheettag">
          <v-sheet class="text-center" height="400px">
            <v-alert v-if="alerttag" width="200px" type="error"
              style="position: fixed; top:  -5px; right: 0 ; z-index: 3 ">{{ msgtag }}</v-alert>
            <v-alert v-if="alerttagsuccess" width="300px" type="success"
              style="position: fixed; top:  -5px; right: 0 ; z-index: 3 ">{{ msgtag }}</v-alert>
            <v-card class="pa-4 pt-4 elevation-0">

              <v-card-text class="pa-0">
                <!-- <v-text-field v-model="tagval" hide placeholder="Tag" filled rounded dense></v-text-field> -->
                <div class="d-flex row mx-1 my-2">
                  <!-- <v-btn @click="sheettag = false" icon flat solo text class="">
                    <v-icon>mdi mdi-arrow-left-thin</v-icon>
                  </v-btn> -->
                  <p class="mb-0 pt-1 title black--text ">Tags</p>
                  <v-tooltip color="black" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mt-n6 pl-2" size="16" color="primary" dark v-bind="attrs" v-on="on">
                        mdi mdi-information-outline
                      </v-icon>
                    </template>
                    <span class="caption">Select from the list or Add new one</span>
                  </v-tooltip>

                  <v-text-field style="max-width: 190px;" v-model="searchText" class="tophundraedmutual  ml-auto"
                    height="36px" background-color="#F1F3F8" @input="searcharray" label="Search tags" dense
              rounded
             
                          solo flat >
                    <template v-slot:prepend-inner>
                      <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                    </template>
                  </v-text-field>
                </div>
              </v-card-text>
              <v-divider class="mt-n6"></v-divider>
              <!-- {{ outputArray }}
{{ filteredArray }}  -->
              <v-row class="mx-5">

                <v-col class="d-flex pa-0 pt-4">
                  <v-text-field :rules="rules" v-model="tagnameadd" class="ml-2" label="Add new tag"></v-text-field>

                  <div class="mt-5  ml-5">
                    <v-menu class="" v-model="menu" dialog-bottom-transition="105" nudge-left="16"
                      :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div :style="{ backgroundColor: color }"
                          style="width: 30px ; height: 30px; cursor: pointer; border-radius: 30px" v-on="on"></div>
                      </template>
                      <v-card>
                        <div class="color-palette">
                          <div v-for="(color, index) in colors" :key="index" :style="{ backgroundColor: color }"
                            @click="selectColor(color)" class="color-box"></div>
                        </div>
                      </v-card>
                      <div class="selected-color" v-if="selectedColor">
                        Selected Color: <span :style="{ backgroundColor: selectedColor }">{{ selectedColor }}</span>
                      </div>
                    </v-menu>
                  </div>


                </v-col>
              </v-row>
              <v-card style=" 
overflow: scroll;" height="180px" color="#F1F3F8" class="rounded-lg elevation-0 mt-4 mx-5 pt-2">




                <v-col cols="12" v-if="outputArray.length == 0 || filteredArray.length == 0" class="text-center mt-15">
                  <!-- <img alt="" class="shrink" src="../../../assets/nodata.svg" width="20%" height="" /> -->
                  <h5>No tags available please add new one</h5>
                </v-col>
                <span v-else>
                  <span v-for="item in filteredArray" :key="item.index">
                    <v-chip :color="item.color" @click="selectfromsearch(item.text)" class="pa-0 ma-2">

                      <p class="mb-0 pa-2 white--text caption">{{ item.text }}</p>
                    </v-chip>

                  </span>
                </span>


              </v-card>
              <v-btn v-if="tagnameadd.length < 3" :loading="loading4" disabled outlined @click="newtagadd()"
                style="width: 90%" class="  mt-4 mx-4 text-capitalize " 
              rounded
             
                          solo flat >Add
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn v-else :loading="loading4" style="background-color: black; width: 90%" @click="newtagadd()"
                class="px-3 mt-4 text-capitalize white--text" 
              rounded
             
                          solo flat  text>Add
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>

            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import { apiurl } from '../../../Api.js'
// import { apiurll } from '../../../Api.js'
import axios from "axios"
// import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import zebupdfImage from '../../../assets/zebupdf.png';

import ExcelJS from 'exceljs';
import imagePath from '@/assets/zebulogo.svg';
export default {
  data() {
    return {
      // tab: null,
      sheettag: false,
      ltparray: [],
      expanded: [],
      color: '#FF80AB',
      mask: '!#XXXXXXXX',
      qntdep: '',
      menu: false,
      searcharray: '',
      singleExpand: true,
      filterrtagvalue: 'All',
      dessertHeaders: [
        { text: 'Company', align: 'start', sortable: false, value: 'SCRIP_SYMBOL', width: '14%' },
        { text: '', value: 'famacc', width: '14%' },
        { text: 'Quality', value: 'NET', width: '12%', align: 'right' },
        { text: 'Buy price', value: 'SCRIP_VALUE', width: '12%', align: 'right' },
        { text: 'Close Price', value: 'last_close', width: '12%', align: 'right' },
        { text: 'P&L', value: 'pnl', width: '12%', align: 'right' },
        { text: 'P&L Change', value: 'pnl_perc', width: '12%', align: 'right' },
        { text: '', value: 'data-table-expand', width: '2%' },
      ],
      dialogholdinglittle: false,
      dessertHeadersfam: [
        {
          text: '',
          // expandmbl: false,
          align: 'start',
          sortable: false,
          value: 'cc',
          width: ''
        },


        { text: '', value: 'NET', width: '', },
        { text: '', value: 'buy_price', width: '', },
        { text: ' ', value: 'last_close', width: '', },
        { text: '', value: 'pnl', width: '', },
        { text: '', value: 'pnl_perc', width: '', },
        { text: '', value: 'data-table-expand', width: '' },
      ],
      familyaccarray: [],
      dessertstabs: [],
      filterrtag: [],
      items: [
        'Overview',
        'Family'
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      filterr: [
        "All", "Equity", "SME", "ETF", "Bonds", "REITS", "INVITS","MF"
      ],
      dialog: false,
      dessertsforbuy: [],
      alert: false,
      headersforbuy: [
        // { text: 'ISIN', value: 'isin', align: 'start' },

        { text: 'Trade Date', value: 'pur_date' },
        // { text: 'Description', value: 'description' },
        { text: 'Quantity', value: 'qty', align: 'end' },

        {
          text: 'Buy price',
          align: 'end',
          sortable: false,
          value: 'buy_price',
        },
        // { text: 'Iron (%)', value: 'iron' },
      ],
      menu2: false,
      dessertspop: [],
      badgearr: [],
      filtertagduplicatearr: [],
      addtag: false,
      dialogbuy: false,
      loader1: false,
      sheet: false,
      tagnameadd: '',
      // filterr: [
      //   "Equities", "Bonds", "Mutual Funds"
      // ],
      radios: null,
      msg: '',
      filterdata_unpl: "All",
      profiledetails : [],
      cname : '',
      itemsdwl: [
        {
          title: 'Download Excel',
        },
        {
          title: 'Download Pdf',
        },
      ],
      symbuypop: '',
      colors: ['#FF80AB', '#8C9EFF', '#FF7043', '#00ACC1', '#E57373', '#00E676'],
      colorsbadge: ['#FF80AB', '#8C9EFF', '#FF7043', '#00ACC1'],

      selectedColor: null,
      sheetfam: false,
      formattedDate: '',
      loadermobile: false,
      search: '',
      more: true,
      less: false,
      rules: [
        // value => !!value || 'Required.',
        // value => (value && value.length >= 3) || 'Min 3 characters',

      ],
      itemperpage: 0,
      itemperpageinner: 0,
      tab: null,
      tabs: null,
      selectedRows: null,
      loader: true,
      // filteredArray: [],
      outputArray: [
        // { "text": 'Long term', "color": "red" },
        // { "text": 'Short Term', "color": "green" }
      ],
      desserts: [],
      dessertsdense: [],
      selectedItem2: false,
      ltpvalarray: [],
      headerval: [],
      loading4: false,
      val1: '',
      val2: '',
      val3: '',
      intervalId: null,
      headersdense: [
        { text: 'Date', align: 'start', value: 'PUR_DATE' },

        {
          text: 'Quantity',
          align: 'end',
          sortable: false,
          value: 'QUANTITY',
        },
        {
          text: 'Buy Price', value: 'PRICE_PREMIUM', align: 'end',
          sortable: false,
        },
        { text: 'Description', align: 'start', value: 'Description', sortable: false, },

      ],
      bottomholdingsheet: false,
      Overviewheaders: [
        { text: "Company", value: "SCRIP_NAME", class: "headerfont" },

        // { text: "", value: "chip", class: "headerfont" },
        { text: "", value: "", align: '', class: "headerfont" },
        { text: "Quantity", value: "NET", class: "headerfont", align: 'right' },
        // { text: "Buy avg.", value: "" },
        { text: "Buy Price", value: "buy_price", class: "headerfont", align: 'right' },

        { text: "LTP", value: "last_close", class: "headerfont", align: 'right' },
        // { text: "Present value", value: "present", align: 'end', class: "headerfont" },
        { text: "P&L", value: "pnl", class: "headerfont", align: 'right' },
        { text: "P&L Change", value: "pnl_perc", class: "headerfont", align: 'right' },
      ],
      addprice: '',
      addquantity: '',
      famexpandarray: [],
      datepop: '',
      maxquantity: '',
      searchText: '',
      symexpandfam: '',
      alerttag: false,
      alerttagsuccess: false,
      msgtag: '',
      selectedTag: '', // Default to 'All'
      // filteredArray: [],
      filteredArraytag: [],
      uniqueTags: [],
    };
  },
  computed: {

    filteredDesserts() {
      const searchTerm = this.search.toLowerCase();
      return this.filteredArraytag.filter(item => item.SCRIP_NAME.toLowerCase().includes(searchTerm));
    },
    filteredArray() {
      // Filter the outputArray based on the search text
      const searchTerm = this.searchText.toLowerCase();
      return this.outputArray.filter(item => item.text.toLowerCase().includes(searchTerm));

      // const filtered = this.outputArray.filter((item) => {
      //   if (typeof item.text === "string") {
      //     return item.text.toLowerCase().includes(this.searchText.toLowerCase());
      //   } else {
      //     return false; // Exclude non-string items
      //   }
      // });

      // // Remove duplicates from the filtered array
      // const uniqueFiltered = Array.from(new Set(filtered));

      // return uniqueFiltered;
    },
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },

  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },

  },
  methods: {
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    calculatePercentage(item) {
    if (this.ltpvalarray[item.Token] && item.buy_price != 0) {  
      const lp = parseFloat(this.ltpvalarray[item.Token].lp);
      const buyPrice = parseFloat(item.buy_price);
      const net = parseFloat(item.NET);

      const percentage = (((lp - buyPrice) * net) / (buyPrice * net)) * 100;
      return percentage.toFixed(2);
    }else if(item.buy_price == 0){
      return 0;
    }else{
      const lp = item.nav_price;
      const buyPrice = parseFloat(item.buy_price);
      const net = parseFloat(item.NET);

      const percentage = (((lp - buyPrice) * net) / (buyPrice * net)) * 100;
      return percentage.toFixed(2);
    }

  },
  calculateColor(item) {
    const percentage = this.calculatePercentage(item);

    if (percentage < 0) {
      return 'red';
    } else if (percentage > 0) {
      return 'green';
    } else {
      return 'black';
    }
  },
    filterArray() {
      this.filterdata_unpl = 'All'
      if (this.selectedTag == 'All') {
        // Show all elements when 'All' is selected or nothing is selected
        this.filteredArraytag = this.filtertagduplicatearr;
      } else {
        // Filter the array based on the selected tag
        this.filteredArraytag = this.filtertagduplicatearr.filter(item =>
          item.tags.some(tag => tag.text === this.selectedTag)
        );
      }
    },

    onRowClick(item) {
      // Handle the row click event here
      // console.log('Clicked row data:', item.avg_res);
      this.dialogholdinglittle = true
      this.dessertsdense = item.avg_res
      this.symexpandfam = item.SCRIP_NAME

      // You can perform additional actions with the row data as needed
    },

    onRowClickmobile(item) {
      // Handle the row click event here
      // console.log('Clicked row data:', item.avg_res);
      this.bottomholdingsheet = true
      this.dessertsdense = item.avg_res
      this.symexpandfam = item.SCRIP_NAME

      // You can perform additional actions with the row data as needed
    },

    expandmblclick(item) {
      // console.log(this.sheetfam);
      this.sheetfam = true
      this.famexpandarray = item.famacc[0]
      this.symexpandfam = item.SCRIP_NAME
      // if (this.expandmbl == true) {
      //   this.expandmbl = false
      // } else if (this.expandmbl == false) {
      //   this.expandmbl = true

      // }
    },

    toggleExpanded(item) {
      const index = this.expanded.indexOf(item);
      if (index === -1) {
        this.expanded = this.singleExpand ? [item] : [...this.expanded, item];
      } else {
        this.expanded = this.expanded.filter((el) => el !== item);
      }
    },
    selectColor(color) {
      this.color = color;
      this.menu = false
    },

    // filtertagg(){
    //     // var data = this.filtertagduplicatearr
    //     // var tag = filterrtagvalue
    //     console.log()
    //     if (this.filterrtagvalue === 'All') {
    //     // Show all elements when 'All' is selected
    //     this.filteredArray = this.desserts;
    //   } else {
    //     // Filter the array based on the selected tag
    //     this.filteredArray = this.desserts.filter(item => item.tags.some(tag => tag.text === this.filterrtagvalue));
    //   }
    // },
    filterseg(filterdata_unpl) {
      this.selectedTag = 'All'
      var data = this.dessertspop
      // expense_amt
      // unrealised_pnl
      // realised_pnl

      // const dataval = this.desserts
      // const data2 = this.desserts
      // const data3 = this.desserts
      const seg = filterdata_unpl
      // // //   //console.log("sssss", seg);
      if (seg == 'Equity') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'Equity') {
            this.filteredArraytag.push(data[i])
          }
        }
        if (this.filteredArraytag && this.filteredArraytag.length > 0) {
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
            return accumulator + object.invested;
          }, 0);
          this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
            return accumulator + object.present_no_buy_price;
          }, 0);
          this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
            return accumulator + object.present_with_buy_price;
          }, 0);
          this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
            return accumulator + object.pnl_stat;
          }, 0);
        }else{
          this.headerval.total_invested = 0
          this.headerval.holdings_value_no_buyprice = 0
          this.headerval.holdings_value_buyprice = 0
          this.headerval.total_pnl = 0
        }

        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................


        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................

        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................

        // console.log(this.headerval.total_pnl, 'pnl');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        // console.log(this.headerval.pnl_perc_stat, 'pnl per');
        // ........................................................................................
      } else if (seg == 'REITS') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'REITS') {
            this.filteredArraytag.push(data[i])
          }
        }
        if (this.filteredArraytag && this.filteredArraytag.length > 0) {
              this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
              return accumulator + object.invested;
            }, 0);
            this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
              return accumulator + object.present_no_buy_price;
            }, 0);
            this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
              return accumulator + object.present_with_buy_price;
            }, 0);
            // ........................................................................................
            this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
              return accumulator + object.pnl_stat;
            }, 0);
        }else{
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0
        }
        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................

        // // //   //console.log(this.filteredArraytag, "this.filteredArraytag");
      } else if (seg == 'INVITS') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'INVITS') {
            this.filteredArraytag.push(data[i])
          }
        }

        if (this.filteredArraytag && this.filteredArraytag.length > 0) { 
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.invested;
        }, 0);
        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_no_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_with_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.pnl_stat;
        }, 0);
        }else{ 
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0
        }
        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................
        // // //   //console.log(this.filteredArraytag, "this.filteredArraytag");
      } else if (seg == 'ETF') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'ETF') {
            this.filteredArraytag.push(data[i])
          }
        }
        if (this.filteredArraytag && this.filteredArraytag.length > 0) { 
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.invested;
        }, 0);
        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_no_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_with_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.pnl_stat;
        }, 0);
        }else{ 
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0 
        }
        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................
        // // //   //console.log(this.filteredArraytag, "this.filteredArraytag");
      } else if (seg == 'SME') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'SME') {
            this.filteredArraytag.push(data[i])
          }
        }
        if (this.filteredArraytag && this.filteredArraytag.length > 0) { 
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.invested;
        }, 0);
        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_no_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_with_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.pnl_stat;
        }, 0);
        }else{
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0 
         }
        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................
        // // //   //console.log(this.filteredArraytag, "this.filteredArraytag");
      } else if (seg == 'Bonds') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'Bonds') {
            this.filteredArraytag.push(data[i])
          }
        }
        if (this.filteredArraytag && this.filteredArraytag.length > 0) {
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.invested;
        }, 0);
        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_no_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_with_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.pnl_stat;
        }, 0);
         }else{
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0 
          }

        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................
        // // //   //console.log(this.filteredArraytag, "this.filteredArraytag");
      }
      else if (seg == 'MF') {
        this.filteredArraytag = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].seg_type == 'MF') {
            this.filteredArraytag.push(data[i])
          }
        }
        if (this.filteredArraytag && this.filteredArraytag.length > 0) {
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.invested;
        }, 0);
        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_no_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_with_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.pnl_stat;
        }, 0);
         }else{
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0 
          }

        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................
        // // //   //console.log(this.filteredArraytag, "this.filteredArraytag");
      }
      else if (seg == 'All') {
        this.filteredArraytag = data
        if (this.filteredArraytag && this.filteredArraytag.length > 0) { 
          this.headerval.total_invested = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.invested;
        }, 0);
        //console.log(this.headerval.total_invested, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_no_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.holdings_value_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.present_with_buy_price;
        }, 0);
        //console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
        // ........................................................................................
        this.headerval.total_pnl = this.filteredArraytag.reduce((accumulator, object) => {
          return accumulator + object.pnl_stat;
        }, 0);
        }else{ 
          this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0 
        }

        
        //console.log(this.headerval.total_pnl, 'this.headerval.total_invested');
        // ........................................................................................
        let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
        this.headerval.pnl_perc = (valofpnl).toFixed(2)
        //console.log(this.headerval.pnl_perc, 'this.headerval.total_invested');
        // ........................................................................................
      }
    },

    delettag(item, textdelete) {
      //console.log(item, textdelete);
      // let textdelete = item.tags
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.actid,
        "tName": textdelete,
        "symbol": item.SCRIP_SYMBOL
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.164:5050/holdingsTagDelete',
        url: `${apiurl}/holdingsTagDelete`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then(() => {
          // console.log(JSON.stringify(response.data));
          const axios = require('axios');
          let data = JSON.stringify({
            "cc": this.actid
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://192.168.5.164:5050/holdingsTag',
            url: `${apiurl}/holdingsTag`,

            headers: {
              'Content-Type': 'application/json',
              'clientid': this.actid,
              'Authorization': this.susertoken
            },
            data: data
          };

          axios.request(config)
            .then((response) => {
              //console.log(JSON.stringify(response.data));
              let inputObject = response.data.tagArr

              let outer = response.data.colorArr
              //console.log(inputObject);

              //   //console.log(Object.keys(object1));

              let arrayofkeys = []
              //   //console.log(Object.keys(object1));
              arrayofkeys = Object.keys(outer);
              for (let i = 0; i < arrayofkeys.length; i++) {
                axiosthis.outputArray.push({ "text": arrayofkeys[i], "color": outer[arrayofkeys[i]] })
              }
              // axiosthis.filteredArray = axiosthis.outputArray
              const outerarr = Array.from(new Set(axiosthis.outputArray.map(JSON.stringify))).map(JSON.parse);
              axiosthis.outputArray = outerarr
              //console.log(axiosthis.outputArray, "kjkjkjj");
              //console.log(axiosthis.outputArray, "key");

              // for (let i = 0; i < keyarr.length; i++) {
              for (let y = 0; y < axiosthis.desserts.length; y++) {
                // if (keyarr[i] === axiosthis.desserts[y].SCRIP_SYMBOL) {
                axiosthis.desserts[y].tags = []
                //console.log("Match found!", axiosthis.desserts[y].SCRIP_SYMBOL);
                let namesy = axiosthis.desserts[y].SCRIP_SYMBOL;
                let pushdata = []
                for (let val in inputObject[namesy]) {
                  //console.log(inputObject[namesy], val)
                  pushdata.push({ "text": inputObject[namesy][val], "color": outer[inputObject[namesy][val]] })

                }
                axiosthis.desserts[y].tags = pushdata
                // }
                //console.log("Match fouomkko", axiosthis.desserts[y].tags);

                // }
              }

              this.selectedItem2 = true
              this.mainholdings()


            })
            .catch((error) => {
              console.log(error);
            });


        })
        .catch((error) => {
          console.log(error);
        });

    },

    selectItem2(item) {
      this.selectedItem2 = item;
    },
    unSelectItem2() {
      this.selectedItem2 = false;
    },

    poptag(item) {
      this.dialogholdinglittle = false
      this.tagnameadd = ''
      this.dialog = true
      //console.log(item);
      this.sym = item.SCRIP_SYMBOL
      // let inputObject = item.tags
      //console.log(this.sym);
      this.tagval = ''
      // this.model = [].concat(...Object.values(inputObject));
      //   //console.log( this.model," this.model");
    },
    
    poptagmobile(item) {
      this.tagnameadd = ''
      this.sheettag = true
      //console.log(item);
      this.sym = item.SCRIP_SYMBOL
      // let inputObject = item.tags
      //console.log(this.sym);
      this.tagval = ''
      // this.model = [].concat(...Object.values(inputObject));
      //   //console.log( this.model," this.model");
    },

    newtagadd() {
      this.loader = 'loading4'
      if (this.tagnameadd != '') {
        const axios = require('axios');
        let data = JSON.stringify({
          "cc": this.actid,
          "tagName": this.tagnameadd,
          "tagColor": this.color
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          // url: 'http://192.168.5.164:5050/holdingsTagCreate',
          url: `${apiurl}/holdingsTagCreate`,

          headers: {
            'Content-Type': 'application/json',
            'clientid': this.actid,
            'Authorization': this.susertoken
          },
          data: data
        };
        let axiosthis = this
        axios.request(config)
          .then((response) => {
            //console.log(JSON.stringify(response.data));

            let errmsg = response.data
            let msg = response.data
            if (errmsg == 'Tag Already Available') {
              axiosthis.msgtag = 'Already Available'
              axiosthis.alerttag = true
              axiosthis.tagnameadd = ''
              setTimeout(() => {
                axiosthis.alerttag = false

                axiosthis.msgtag = ''
              }, 3000);

            } else if (msg == 'Tag Created') {
              axiosthis.msgtag = 'Tag Created'
              axiosthis.alerttagsuccess = true
              axiosthis.tagnameadd = ''

              setTimeout(() => {
                axiosthis.alerttagsuccess = false

                axiosthis.msgtag = ''
              }, 3000);
            }
            axiosthis.mainholdings()
            

            axiosthis.selectedItem2 = true

          })
          .catch((error) => {
            console.log(error);
          });

      }


    },

    selectfromsearch(item) {
      //console.log(item);
      //   //console.log(this.tagval);
      //console.log(this.sym, "kjkj");
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.actid,
        "tName": item,
        "symbol": this.sym
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.164:5050/holdingsTagAdd',
        url: `${apiurl}/holdingsTagAdd`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          // let inputObject = response.data
          let errmsg = response.data.msg
          if (errmsg == 'symbol already in the tag') {
            axiosthis.msgtag = 'Already selected'
            axiosthis.alerttag = true

            setTimeout(() => {
              axiosthis.alerttag = false

              axiosthis.msgtag = ''
            }, 3000);

          } else {
            axiosthis.dialog = false
            axiosthis.sheettag = false
            axiosthis.selectedItem2 = true;



            // for (let i = 0; i < keyarr.length; i++) {
            const axios = require('axios');
            let data = JSON.stringify({
              "cc": this.actid
            });

            let config = {
              method: 'post',
              maxBodyLength: Infinity,
              // url: 'http://192.168.5.164:5050/holdingsTag',
              url: `${apiurl}/holdingsTag`,

              headers: {
                'Content-Type': 'application/json',
                'clientid': this.actid,
                'Authorization': this.susertoken
              },
              data: data
            };

            axios.request(config)
              .then((response) => {
                //console.log(JSON.stringify(response.data));
                let inputObject = response.data.tagArr
                let outer = response.data.colorArr

                //console.log(inputObject);

                //   //console.log(Object.keys(object1));

                let arrayofkeys = []
                //   //console.log(Object.keys(object1));
                arrayofkeys = Object.keys(outer);
                for (let i = 0; i < arrayofkeys.length; i++) {
                  axiosthis.outputArray.push({ "text": arrayofkeys[i], "color": outer[arrayofkeys[i]] })
                }
                // axiosthis.filteredArray = axiosthis.outputArray
                const outerarr = Array.from(new Set(axiosthis.outputArray.map(JSON.stringify))).map(JSON.parse);
                axiosthis.outputArray = outerarr
                //console.log(axiosthis.outputArray, "kjkjkjj");
                //console.log(axiosthis.outputArray, "key");

                // for (let i = 0; i < keyarr.length; i++) {
                for (let y = 0; y < axiosthis.desserts.length; y++) {
                  // if (keyarr[i] === axiosthis.desserts[y].SCRIP_SYMBOL) {
                  axiosthis.desserts[y].tags = []
                  //console.log("Match found!", axiosthis.desserts[y].SCRIP_SYMBOL);
                  let namesy = axiosthis.desserts[y].SCRIP_SYMBOL;
                  let pushdata = []
                  for (let val in inputObject[namesy]) {
                    //console.log(inputObject[namesy], val)
                    pushdata.push({ "text": inputObject[namesy][val], "color": outer[inputObject[namesy][val]] })

                  }
                  axiosthis.desserts[y].tags = pushdata
                  // }
                  //console.log("Match fouomkko", axiosthis.desserts[y].tags);

                  // }
                }


                axiosthis.selectedItem2 = true
                axiosthis.mainholdings()
              })
              .catch((error) => {
                console.log(error);
              });
          }


        })
        .catch((error) => {
          console.log(error);
        });

    },

    // addtrade() {
    //   let dateString = this.datepop
    //   let dateObject = new Date(dateString);
    //   let year = dateObject.getFullYear();
    //   let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
    //   let day = dateObject.getDate();
    //   this.formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
    //   //   //console.log(this.symbuypop, "this.symbuypop");
    //   if (this.addquantity && this.addprice && this.formattedDate) {
    //     const axios = require('axios');
    //     let data = JSON.stringify({
    //       "price": this.addprice,
    //       "date": this.formattedDate,
    //       "qty": this.addquantity,
    //       "symbol": this.symbuypop,
    //       "cc": this.actid
    //     });

    //     let config = {
    //       method: 'post',
    //       maxBodyLength: Infinity,
    //       // url: 'http://192.168.5.164:5050/',
    //       url: `${apiurl}/holdingsmanualadd`,

    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       data: data
    //     };

    //     axios.request(config)
    //       .then((response) => {
    //           //console.log(JSON.stringify(response.data));

    //         this.dialogbuy = false
    //         this.addtag = true
    //         this.mainholdings();
    //       })
    //       .catch((error) => {
    //           //console.log(error);
    //       });
    //   } else {
    //     // alert("fill please")s
    //   }


    // },
    familyaccountcheck() {
      const axios = require('axios');
      let data = JSON.stringify({
        "clientid": this.actid
        // "clientid": "ZP00212"
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://rekycbe.mynt.in/family_linked_status',
        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          var arrayacc = response.data.accessable
          axiosthis.badgearr = response.data.accessable
          // console.log(arrayacc, "arrayacc");
          arrayacc.push(this.actid)
          // console.log(arrayacc, "arrayacc");

          axiosthis.familyaccarray = arrayacc
          axiosthis.getfamilyaccountdetails();

          // console.log(axiosthis.familyaccarray, "jhjhjh");
          // axiosthis.getfamilyaccountdetails();
        })
        .catch((error) => {
          console.log(error);
        });

    },
    
    getfamilyaccountdetails() {
      // this.familyaccarray.append(this.actid)
      this.loader1 = true
      // console.log(this.familyaccarray);
      const axios = require('axios');
      let data = JSON.stringify({
        "cc": this.familyaccarray
        // "cc": [
        //   "TN1V2",
        //   // "ZP00285",
        //   "ZVK0106"],
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.212:5050/getHoldingsFamily',
        url: `${apiurl}/getHoldingsFamily`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          axiosthis.loader1 = false
          // console.log(response.data);
          axiosthis.dessertstabs = response.data.grp_data
          axiosthis.itemperpageinner = axiosthis.dessertstabs.length
          var famdata = response.data.sym_data
          // var holdData = response.data.grp_data
          // console.log(famdata[1]['182D020524-TB']);
          // for (let i = 0; i < axiosthis.dessertstabs.length; i++) {
          //   let dessertTab = axiosthis.dessertstabs[i];
          //   let smdata = dessertTab.SCRIP_SYMBOL;[0]

          //   for (let y = 0; y < famdata.length; y++) {
          //     let famItem = famdata[y][smdata];

          //     // Check if famItem is an object before accessing its property
          //     if (famItem && famItem['SCRIP_SYMBOL'] && smdata === famItem['SCRIP_SYMBOL']) {
          //       console.log('found');
          //       if (!dessertTab.famacc) {
          //         dessertTab.famacc = [];
          //       }
          //       dessertTab.famacc.push(famItem);
          //     }
          //   }
          // }
          // simply

          // console.log(famdata[0])
           
          for (let i = 0; i < axiosthis.dessertstabs.length; i++) {
              const smdata = axiosthis.dessertstabs[i].SCRIP_SYMBOL
              // for (let j = 0; j < famdata.length; j++) {
              // console.log(famdata[smdata][0].SCRIP_SYMBOL);
                const famItemSymbol = famdata[smdata][0].SCRIP_SYMBOL
                console.log(smdata === famItemSymbol);
                let colorsbadge = ['#00ACC1', '#7CB342', '#4DB6AC', '#FB8C00'];
                if (smdata === famItemSymbol) {
                  // axiosthis.dessertstabs[i].famacc ??= [];
                  if(!axiosthis.dessertstabs[i].famacc){
                    axiosthis.dessertstabs[i].famacc = [];
                    axiosthis.dessertstabs[i].famacc.push(famdata[famItemSymbol])
                  }
                  else{
                  axiosthis.dessertstabs[i].famacc.push(famdata[famItemSymbol])
                  }
                  for (let k = 0; k < axiosthis.dessertstabs[i].famacc.length; k++) {
                    axiosthis.dessertstabs[i].famacc[k].cc_Color = colorsbadge[k];
                  }
                // }
              }
          }


          console.log(axiosthis.dessertstabs,"val val");

        })
        .catch((error) => {
          console.log(error);
        });

    },
    buypop(item) {
      this.datepop = ''
      this.addprice = ''
      // this.addtag = false,
      this.dialogbuy = true
      let dada = item
      this.qntdep = dada.NET
      this.addquantity = dada.NET - dada.updated_qty
      this.maxquantity = dada.NET - dada.updated_qty
      this.symbuypop = dada.ISIN
      //console.log(dada, this.qntdep, "this.qntdep");
      const axios = require('axios');
      let data = JSON.stringify({
        "symbol": this.symbuypop,
        "cc": this.actid
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.164:5050/holdingsmanuallist',
        url: `${apiurl}/holdingsmanuallist`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          //   //console.log(JSON.stringify(response.data));
          this.dessertsforbuy = response.data
        })
        .catch((error) => {
          console.log(error);
        });

    },
    download() {
      // const data = this.desserts;

      // const exportType = exportFromJSON.types.csv;
      // const fileName = `${'Holdings'}`

      // if (data) exportFromJSON({ data, fileName, exportType });

    },
    convertImageToBase64() {

fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.imageBase64 = reader.result;
        };
        reader.readAsDataURL(blob);
    })
    .catch((error) => {
        console.error('Error loading local image:', error);
    });
},
    valdwl(item) {
      this.sheet = false

      let data = item
      //   //console.log("dsdsdsdsds", data);

      if (data === "Download Excel") {
        // console.log("dsdsdsdsdsexceelll", );
        // console.log("this.desserts", this.desserts);
        // console.log("this.desserts",this.desserts );

  if (!this.desserts || !Array.isArray(this.desserts)) {
    console.error('Invalid data for Excel export.');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Holdings');

  // Define header rows
  const headerRows = [
    // ["ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED"],
    // [],
    ["","Holdings" , "","Client ID", this.actid],
    ["","" , "","Client Name", this.cname],
    ["","", "","Client PAN", this.profiledetails['PAN_NO']],
    ["","", "","Client email", this.profiledetails['CLIENT_ID_MAIL']],
    [],
    ["Total Investment", "Current Value",  "P&L "],
    [
      (this.headerval.total_invested || 0).toFixed(2), 
    
      (this.headerval.holdings_value_no_buyprice || 0).toFixed(2), 
  
       `${(this.headerval.total_pnl || 0).toFixed(2)} (${(this.headerval.pnl_perc || 0)}%)`
    ],
    [],
    ["Company", "Net", "Buy price", "LTP", "P&L", "P&L change", "Invested"]
  ];

  // Prepare rows data
  const rows = this.desserts.map(element => [
    element.SCRIP_NAME || "",
    element.NET || 0,
    element.buy_price || 0,
    element.ltp || 0,
    element.pnl_stat || 0,
    element.pnl_perc_stat || 0,
    element.invested || 0
  ]);

  // Combine headerRows with data rows
  const allRows = headerRows.concat(rows);
  const footerRows = [
    [],
    ["Zebu Share And Wealth Management Private Limited"],
    ["Correspondence Office: Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42"],
    ["Phone No: 044-4855 7991, Fax: 044-4855 7991"],
    ["TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300"],
    ["CIN NO: U67120TZ2013PTC019704 Website Address: www.zebuetrade.com, Investor Grievances Email id: grievance@zebuetrade.com"]
  ];

  allRows.push(...footerRows);
  // Add rows to the worksheet
  allRows.forEach((row) => {
    const excelRow = worksheet.addRow(row);

    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);

      if (cell === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }

      // Apply font and fill styles based on cell content
      if (['ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Holdings'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 14, bold: true };
      } else if (['Company', 'Net', 'Buy price', 'LTP', 'P&L', 'P&L change', 'Invested'].includes(cell)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      else if (['Total Investment', 'Current Value', 'P&L '].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 11, bold: true };
        cellRef.font = { bold: true };
      }

      // Align all cells to the left
      cellRef.alignment = { horizontal: 'left' };
    });
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  const imageId = workbook.addImage({
    base64: this.imageBase64,
    extension: 'svg'
  });

  worksheet.getRow(1).height = 32.53;
  worksheet.getColumn(1).width = 20;

  worksheet.addImage(imageId, {
    tl: { col: 0, row: 0 },
    ext: { width: 102.53, height: 40.53 }
  });



  // Write the Excel file to a buffer
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Holdings.xlsx';
    link.click();
  }).catch(error => console.error('Error generating Excel file:', error));
}
 else if (data == 'Download Pdf') {
                let rows = [];
                let rowsexpense = [];
                // let headerexpense = [];
                let columnHeader = ['Company', 'Quantity', 'Buy Price', 'LTP', 'P&L', 'P&L Change'];


                let pdfName = 'Holdings';
                // for (let i = 0; i < this.dessertsexpense.length; i++) {
                //     var temp3 = 
                //     this.dessertsexpense[i].NET_AMOUNT 
                    
                //     rowsexpense.push(temp3)

                // }
                // this.dessertsexpense.forEach(element => {
                //     var temp3 = [
                //         element.NET_AMOUNT
                //     ]
                //     rowsexpense.push(temp3)
                // });
                console.log(rowsexpense);
                this.filteredArraytag.forEach(element => {
                    var temp = [
                        element.SCRIP_NAME, element.NET, element.buy_price, element.last_close, element.pnl, element.pnl_perc
                    ];
                    rows.push(temp);
                });
                // console.log(headerexpense);
                // console.log(rows);
                // Create a new jsPDF document
                var doc = new jsPDF();
                // Add header and customize text
                //doc.setFont('Tenon');
                // doc.setFontSize(12);
                // doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
                // doc.setFontSize(6);
                // doc.text("Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42", 38, 13);
                // doc.setFontSize(6);
                // doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
                // doc.setFontSize(6);
                // doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
                // doc.setFontSize(6);
                // doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
                doc.setFontSize(7);
                doc.setFont('bold');
                doc.text(`${'Client Name   :  '}${this.cname}`, 15, 31);
                doc.setFontSize(7);
                doc.text(`${'Client Code    :  '}${this.actid}`, 15, 35);
                doc.setFontSize(7);
                doc.text(`${'PAN               :  '}${this.profiledetails['PAN_NO']}`, 15, 39);
                doc.setFontSize(7);
                doc.text(`${'Email Id         :  '}${this.profiledetails['CLIENT_ID_MAIL']}`, 15, 43);
                // doc.setFontSize(7);
                // doc.text(`${'From     :  '}${this.fromshow.split("_to_")[0]}`, 133, 29);
                // doc.setFontSize(7);
                // doc.text(`${'To         :  '}${this.fromshow.split("_to_")[1]}`, 133, 33);
                doc.setFontSize(7);
                doc.text(`${'Mobile  :  '}${this.profiledetails['MOBILE_NO']}`, 133, 29);
                doc.setFontSize(7);
                doc.text(`${'Address :  '}${this.profiledetails['CL_RESI_ADD1']}`, 133, 33);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD2']}`, 144, 37);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD3']}`, 144, 41);
                doc.setFontSize(14);
                doc.text('Holdings', 90, 14.5);
                const dividerY = 23; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(0, dividerY, 218, dividerY); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                // doc.text('Ledger', 100, 33);
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                // doc.setLineWidth(0.2); // Set line width
                // doc.setDrawColor(0); // Set line color to black
                // doc.line(0, dividerYy, 218, dividerYy); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                // doc.setLineWidth(0.2); // Set line width
                // doc.setDrawColor(0); // Set line color to black
                // doc.line(0, dividerYy, 218, dividerYy); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                const rectangleX = 15; // X-coordinate of the top-left corner of the rectangle
                const rectangleY = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth = 40; // Width of the rectangle
                const rectangleHeight = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX, rectangleY, rectangleWidth, rectangleHeight); // Draw rectangle border

                // Add text inside the rectangle
                
                const textInsideRectangle = 'Total investment';
                const textInsideRectangleX = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY = rectangleY + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle, textInsideRectangleX, textInsideRectangleY);
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(18, 60, 25, 60); 


                
                // Set font size for the table
                const rectangleX2 = 60; // X-coordinate of the top-left corner of the rectangle
                const rectangleY2 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth2 = 40; // Width of the rectangle
                const rectangleHeight2 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX2, rectangleY2, rectangleWidth2, rectangleHeight2); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle2 = 'Current Value';
                const textInsideRectangleX2 = rectangleX2 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY2 = rectangleY2 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle2, textInsideRectangleX2, textInsideRectangleY2);

                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(63, 60, 70, 60); 
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(108, 60, 115, 60); 

                const rectangleX3 = 105; // X-coordinate of the top-left corner of the rectangle
                const rectangleY3 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth3 = 40; // Width of the rectangle
                const rectangleHeight3 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX3, rectangleY3, rectangleWidth3, rectangleHeight3); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle3 = 'P&L';
                const textInsideRectangleX3 = rectangleX3 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY3 = rectangleY3 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle3, textInsideRectangleX3, textInsideRectangleY3);
                // const rectangleX4 = 150; // X-coordinate of the top-left corner of the rectangle
                // const rectangleY4 = 52; // Y-coordinate of the top-left corner of the rectangle
                // const rectangleWidth4 = 40; // Width of the rectangle
                // const rectangleHeight4 = 17; // Height of the rectangle
                // doc.setDrawColor(0); // Set border color to black
                // doc.setLineWidth(0.1); // Set border line width
                // doc.rect(rectangleX4, rectangleY4, rectangleWidth4, rectangleHeight4); // Draw rectangle border

                // Add text inside the rectangle
                
                // doc.setLineWidth(0.2); // Set line width
                // doc.setDrawColor(0); // Set line color to black
                // doc.line(108, 60, 115, 60); 
                // Add table using autoTable
                let textColor;
                if (this.headerval.total_invested < 0) {
                textColor = [255, 0, 0]; // Red for negative values
                } else if (this.headerval.total_invested > 0) {
                textColor = [0, 128, 0]; // Green for positive values
                } else {
                textColor = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleval1 = (this.headerval.total_invested).toFixed(2);
                const textInsideRectangleval1x = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval1Y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor[0], textColor[1], textColor[2]);
                doc.text(textInsideRectangleval1, textInsideRectangleval1x, textInsideRectangleval1Y);

                // let textColor1;
                // if (this.disunrealised_pnl < 0) {
                // textColor1 = [0, 0, 0]; // Red for negative values
                // } else if (this.disunrealised_pnl > 0) {
                // textColor1 = [0, 128, 0]; // Green for positive values
                // } else {
                // textColor1 = [0, 0, 0]; // Black for zero
                // }
                const textInsideRectangleval21 =(this.headerval.holdings_value_no_buyprice).toFixed(2);
                const textInsideRectangleval21x = rectangleX + 63; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval21y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                // doc.setTextColor(textColor1[0], textColor1[1], textColor1[2]);
                doc.text(textInsideRectangleval21, textInsideRectangleval21x, textInsideRectangleval21y);
                  
                // bb2
                const textInsideRectangleval2 =(this.headerval.holdings_value_buyprice).toFixed(2);
                const textInsideRectangleval2x = rectangleX + 48; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval2y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                // doc.setTextColor(textColor1[0], textColor1[1], textColor1[2]);
                doc.text(textInsideRectangleval2, textInsideRectangleval2x, textInsideRectangleval2y);


                let textColor2;
                if (this.headerval.total_pnl < 0) {
                    textColor2 = [255, 0, 0]; // Red for negative values
                } else if (this.headerval.total_pnl > 0) {
                    textColor2 = [0, 128, 0]; // Green for positive values
                } else {
                    textColor2 = [0, 0, 0]; // Black for zero
                }

                let textColor3;
                if (this.headerval.pnl_perc < 0) {
                    textColor3 = [255, 0, 0]; // Red for negative values
                } else if (this.headerval.pnl_perc > 0) {
                    textColor3 = [0, 128, 0]; // Green for positive values
                } else {
                    textColor3 = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleval3 = (this.headerval.total_pnl).toFixed(2);
                const textInsideRectangleval3x = rectangleX + 93; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval3y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor2[0], textColor2[1], textColor2[2]);
                doc.text(textInsideRectangleval3, textInsideRectangleval3x, textInsideRectangleval3y);
////////////
                const textInsideRectangleval31 = this.headerval.pnl_perc;
                const textInsideRectangleval31x = rectangleX + 108; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval31y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor3[0], textColor3[1], textColor3[2]);
                doc.text(textInsideRectangleval31 + '%', textInsideRectangleval31x, textInsideRectangleval31y);

                doc.setFontSize(8);
                // //doc.setFont('Tenon');
                
                const columnStyles = {
                    0: { fontStyle: 'bold',textColor: [0, 0, 0],halign: 'left'},
                    1 : {halign: 'right'},
                    2 : {halign: 'left'},
                    3 : {halign: 'left'},
                    4 : {halign: 'right'},
                    5:  {halign: 'right'},
                    6 : {halign: 'right'},
                    7 : {halign: 'right'},
                    8 : {halign: 'right'}
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
                // const columnStyles2 = {
                //     0: { fontStyle: 'bold',textColor: [0, 0, 0],align: 'right'},
                //     1 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     2 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     3 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     4 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     5:  {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     6 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     7 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                //     8 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'}
                //     // 1: {} // Index 0 corresponds to the first column ('Symbol')
                // };
                autoTable(doc, { head: [columnHeader], body: rows, startY: 79, styles: {
                    
                            fontSize: 7,
                            // font: 'Tenon',
                            lineColor: [200, 200, 200],
                            // textColor: [0,0,0],
                        },theme: 'grid', // Use the 'grid' theme
                        headStyles: {
                            fillColor: [31, 52, 101], // RGB color for dark blue header background
                            textColor: [255, 255, 255], // White text color for the header
                        },
                        columnStyles: columnStyles,
                        table: {
                            // fillColor: [31, 52, 101]
                            // fillColor: tableBackgroundColor,
                        }, });
//                         if (doc.internal.pages.length > 1) {
//                             doc.addPage();
//                             doc.autoTable.previous.finalY = 10

// } else {
//     doc.autoTable.previous.finalY += 20; // Add some spacing between tables
// }
                        // doc.text('End table', 10, doc.autoTable.previous.finalY + 10);
                        // doc.setFontSize(12);
                        // doc.setTextColor(0, 0, 0);
                        // doc.text('Charges & taxes', 15, doc.autoTable.previous.finalY + 10);
                        // autoTable(doc, {
                        //     head: [headerexpense],
                        //     body: [rowsexpense],
                        //     startY: doc.autoTable.previous.finalY + 20, // Adjust the startY position as needed
                        //     styles: {
                        //         fontSize: 7,
                        //         // font: 'Tenon',
                        //         // textColor: [200, 200, 200], // Set font color to black
                        //     },theme: 'grid',
                        //     headStyles: {
                        //     fillColor: [31, 52, 101], // RGB color for dark blue header background
                        //     textColor: [255, 255, 255], // White text color for the header
                        // },
                        // columnStyles: columnStyles2,

                        //     table: {},
                        // });
                                        // Add custom text
                                        // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                                        // Save the PDF
                                        var totalPages = doc.internal.getNumberOfPages();
                        for (var i = 1; i <= totalPages; i++) {
                            doc.setPage(i);
                            const dividerY = doc.internal.pageSize.height - 13; // Adjust the Y-coordinate based on your layout
                            doc.setFontSize(6);
                            doc.setTextColor(0);
                            doc.text(14, doc.internal.pageSize.height - 9, 'Zebu Share And Wealth Management Private Limited');
                            doc.setFontSize(4);
                            doc.setTextColor(0);
                            doc.text(14, doc.internal.pageSize.height - 7, 'Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42');
                            doc.setFontSize(4);
                            doc.setTextColor(0);
                            doc.text(84, doc.internal.pageSize.height - 7, 'Phone No:044-4855 7991, Fax :044-4855 7991');
                            doc.setFontSize(4);
                            doc.setTextColor(0);
                            doc.text(14, doc.internal.pageSize.height - 5, 'TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704');
                            doc.setFontSize(4);
                            doc.setTextColor(0);
                            doc.text(109, doc.internal.pageSize.height - 5, 'Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com');
                            doc.setLineWidth(0.2); // Set line width 
                            doc.setDrawColor(0); // Set line color to black 
                            doc.line(0, dividerY, 218, dividerY); 
                        }
                                        doc.save(pdfName + '.pdf');
            } else {
        alert("jkl")
      }


      //pdf


    },
    lessbtn() {
      this.more = true
      this.less = false
      this.itemperpage = 9
    },
    morebtn() {
      this.more = false
      this.less = true
      this.itemperpage = this.desserts.length
    },
    calculateHoldingsValue(net, ltp) {
      return net * ltp;
    },
    calculateHoldingsValuee(nett, ltpp) {
      return nett * ltpp;
    },
    ltpGetdata() {
      let data = JSON.stringify({
        "data": this.ltparray
      });

      let config = {
        method: 'post',
        url: 'https://asvr.mynt.in/bcast/GetLtp',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));

          var resarray = []

          resarray = response.data.data;
          axiosthis.ltpvalarray = resarray

          var parsedobj = JSON.parse(JSON.stringify(resarray))
          // console.log("lists s", parsedobj)

          // for (let w = 0; w < axiosthis.desserts.length; w++) {

          //   var list = parsedobj.findIndex(o => o.token== axiosthis.desserts[w].Token);
          //   axiosthis.desserts[w]['ltpdata'] = parseFloat(parsedobj[list].lp)
          //   axiosthis.desserts[w]['pnl_ltp'] = axiosthis.desserts[w]['ltpdata'] - axiosthis.desserts[w]['buy_price'] * axiosthis.desserts[w]['NET']
          //   if (axiosthis.desserts[w]['buy_price'] != 0) {
          //   axiosthis.desserts[w]['pnl_per_ltp'] = (axiosthis.desserts[w]['pnl_ltp'] / axiosthis.desserts[w]['buy_price'] * axiosthis.desserts[w]['NET'] ) * 100
          //   }else{
          //   axiosthis.desserts[w]['pnl_per_ltp'] = 0

          //   }

          // }
          // console.log("lists", axiosthis.desserts);
          // axiosthis.selectedItem2 = true




          // console.log(axiosthis.ltpvalarray[adxiosthis.desserts[0].Token] , "test");
          // this.val1 = resarray[0].lp
          // this.val2 = resarray[1].lp
          // this.val3 = resarray[2].lp
          for (let i = 0; i < axiosthis.filteredArraytag.length; i++) {
            if (parsedobj && parsedobj[axiosthis.filteredArraytag[i].Token] && axiosthis.filteredArraytag[i].Token == parsedobj[axiosthis.filteredArraytag[i].Token]?.token) {
              axiosthis.filteredArraytag[i]['ltp'] = parseFloat((parsedobj[axiosthis.filteredArraytag[i].Token].lp));

              if (axiosthis.filteredArraytag[i]['buy_price'] != 0) {
                axiosthis.filteredArraytag[i]['pnl_stat'] = parseFloat(((parseFloat(axiosthis.filteredArraytag[i]['ltp']) - axiosthis.filteredArraytag[i]['buy_price']) * axiosthis.filteredArraytag[i]['NET']).toFixed(2));
                
                let totalInvest = axiosthis.filteredArraytag[i]['buy_price'] * axiosthis.filteredArraytag[i]['NET'];
                let pnlPercentage = parseFloat((axiosthis.filteredArraytag[i]['pnl_stat'] / totalInvest) * 100);
                axiosthis.filteredArraytag[i]['pnl_perc_stat'] = parseFloat(pnlPercentage.toFixed(2));
                axiosthis.filteredArraytag[i]['buy_val_stat'] = parseFloat(axiosthis.filteredArraytag[i]['ltp']) * axiosthis.filteredArraytag[i]['NET']
                axiosthis.filteredArraytag[i]['no_buy_val_stat'] = 0
                // let holdingsValueBuyPrice = 0
                //  holdingsValueBuyPrice += axiosthis.filteredArraytag[i].buy_val_stat || 0;
                //  axiosthis.headerval.holdings_value_buyprice = holdingsValueBuyPrice
              } else {
                axiosthis.filteredArraytag[i]['pnl_perc_stat'] = 0;
                axiosthis.filteredArraytag[i]['pnl_stat'] = 0;
                axiosthis.filteredArraytag[i]['no_buy_val_stat'] = parseFloat(axiosthis.filteredArraytag[i]['ltp']) * axiosthis.filteredArraytag[i]['NET'];
                axiosthis.filteredArraytag[i]['buy_val_stat'] = 0
                // let holdingsValuenoBuyPrice = 0
                //  holdingsValuenoBuyPrice += axiosthis.filteredArraytag[i].no_buy_val_stat || 0;
                //  axiosthis.headerval.holdings_value_no_buyprice = holdingsValuenoBuyPrice
              }

            } else {
              // Handle the case where parsedobj[axiosthis.filteredArraytag[i].Token] is undefined or doesn't match the condition
            }
          }
          if(axiosthis.filteredArraytag){
          let holdingsValueNoBuyPrice = axiosthis.filteredArraytag.map(obj => obj.no_buy_val_stat || 0).reduce((acc, val) => acc + val, 0);
          this.headerval.holdings_value_no_buyprice = holdingsValueNoBuyPrice;
          let holdingsValueBuyPrice = axiosthis.filteredArraytag.map(obj => obj.buy_val_stat || 0).reduce((acc, val) => acc + val, 0);
          this.headerval.holdings_value_buyprice = holdingsValueBuyPrice
          this.headerval.total_pnl = this.headerval.holdings_value_buyprice - this.headerval.total_invested
          // this.headerval.holdings_value_no_buyprice = this.filteredArraytag.reduce((accumulator, object) => {
          //   return accumulator + object.no_buy_val_stat;
          // }, 0);
          // this.headerval.holdings_value_buyprice = this.desserts.reduce((accumulator, object) => {
          //   return accumulator + object.buy_val_stat;
          // }, 0);
          // let total_pnl = axiosthis.desserts.map(obj => obj.pnl_stat || 0).reduce((acc, val) => acc + val, 0);
          // this.headerval.total_pnl = total_pnl
          // console.log(this.headerval.total_pnl,"dadadada")
          }else{
           this.headerval.total_invested=0
          this.headerval.holdings_value_no_buyprice = 0 
          this.headerval.holdings_value_buyprice =0 
          this.headerval.total_pnl = 0 
          }

          // this.headerval.total_pnl = this.desserts.reduce((accumulator, object) => {
          //   return accumulator + object.pnl_stat;
          // }, 0);
          // console.log(axiosthis.desserts,"test");


          let valofpnl = (this.headerval.total_pnl / this.headerval.total_invested) * 100
          this.headerval.pnl_perc = (valofpnl).toFixed(2)
          // console.log(this.headerval.holdings_value_no_buyprice,this.headerval.holdings_value_buyprice, 'test2');


          // console.log(axiosthis.desserts, 'list1');

          // Create a dictionary for faster lookup
          // const resDict = {};
          // for (const res of resarray) {
          //   resDict[res.token] = res;
          // }

          // // Update axiosthis.desserts
          // for (let i = 0; i < axiosthis.desserts.length; i++) { 
          //   const dessert = axiosthis.desserts[i];
          //   const resObject = resDict[dessert.Token];

          //   if (resObject) {
          //     dessert['ltp'] = resObject.lp;
          //     dessert['pnl'] = parseFloat(((parseInt(dessert['ltp']) - dessert['buy_price']) * dessert['updated_qty']).toFixed(2));

          //     let totalInvest = dessert['buy_price'] * dessert['updated_qty'];
          //     let pnlPercentage = parseFloat((dessert['pnl'] / totalInvest) * 100);
          //     dessert['pnl_perc'] = parseFloat(pnlPercentage.toFixed(2));
          //   }
          // }
          //    this.headerval.holdings_value_no_buyprice = this.desserts.reduce((accumulator, object) => {
          // return accumulator + object.present_no_buy_price;
          //   }, 0);
          //   console.log(this.headerval.holdings_value_no_buyprice, 'this.headerval.total_invested');
          //   // ........................................................................................
          //   this.headerval.holdings_value_buyprice = this.desserts.reduce((accumulator, object) => {
          //     return accumulator + object.present_with_buy_price;
          //   }, 0);
          //   console.log(this.headerval.holdings_value_buyprice, 'this.headerval.total_invested');
          // ........................................................................................
          // this.headerval.total_pnl = this.desserts.reduce((accumulator, object) => {
          //   return accumulator + object.pnl_stat;
          // }, 0);
          // console.log(this.desserts[0].pnl, this.desserts[1].pnl, this.desserts[2].pnl, this.headerval.total_pnl, 'this.headerval.total_invested');
          // ........................................................................................
          // let valofpnl = this.headerval.total_pnl / this.headerval.total_invested * 100
          // this.headerval.pnl_perc = (valofpnl).toFixed(2)



          // axiosthis.selectedItem2 = true

          // console.log(this.headerval.pnl_perc,this.headerval.total_pnl,"pnl_perc_stat");

        })
        .catch((error) => {
          console.log(error);
        });

    },
    
    mainholdings() {
      this.selectedTag = 'All'
      this.loadermobile = true
      this.loader = true
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var todayapi = dd + '/' + mm + '/' + yyyy;
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      let data = JSON.stringify({
        // "cc": this.actid,
        "cc": this.actid,

        "to": todayapi

      });

      let config = {
        method: 'post',
        url: `${apiurl}/getHoldings`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data
      };
      let axiosthis = this;
      axios.request(config)
        .then((response) => {
          var res = response.data
          //  //console.log(res)
          if (res != 'no data found') {
            axiosthis.desserts = response.data['Holdings']
            axiosthis.dessertspop = response.data['Holdings']
            axiosthis.headerval = response.data
            axiosthis.itemperpage = axiosthis.desserts.length
            for (let z = 0; z < axiosthis.desserts.length; z++) {
              let dummydateString = axiosthis.desserts[z].avg_res

              // console.log(dateString); // Output: 12/11/2023
              for (let v = 0; v < dummydateString.length; v++) {
                let dateString = dummydateString[v].PUR_DATE
                if (dateString !== "") {
                  let dateObject = new Date(dateString);

                  // Check if the dateObject is a valid date
                  if (!isNaN(dateObject.getTime())) {
                    let day = dateObject.getDate();
                    let month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
                    let year = dateObject.getFullYear();

                    // Add leading zero if the day or month is a single digit
                    let formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

                    // console.log(formattedDate); // Output: 12/11/2023

                    // Update the property with the formatted date
                    axiosthis.desserts[z].avg_res[v].PUR_DATE = formattedDate;
                    // console.log(axiosthis.desserts[z], "sdsd");

                  } else {
                    // console.error("Invalid date string:", dateString);
                    // Handle the case where the date string is not valid
                    // You might want to log an error or handle it in an appropriate way
                  }
                  // console.log(axiosthis.desserts, "sdsd");

                }

              }

              // Check if the date string is not empty

            }
            for (let u = 0; u < axiosthis.desserts.length; u++) {
              if (axiosthis.desserts[u].avg_res.length > 0) {
                const data = axiosthis.desserts[u].avg_res
                var sortedData = data.sort((a, b) => {
                  const dateA = new Date(a.PUR_DATE.split('/').reverse().join('/'));
                  const dateB = new Date(b.PUR_DATE.split('/').reverse().join('/'));
                  return dateA - dateB;
                });

                // Print the sorted array
                // console.log(sortedData);
                axiosthis.desserts[u].avg_res = sortedData
              }
              // console.log(sortedData, "kjkjkkjshort1");
              // console.log(axiosthis.desserts, "kjkjkkj1");

            }
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              if (axiosthis.desserts[i].Exchange != 0 && axiosthis.desserts[i].Token != 0) {
                axiosthis.ltparray.push({ "exch": axiosthis.desserts[i].Exchange, "token": axiosthis.desserts[i].Token })

              } else {
                //
              }
              // console.log(axiosthis.ltparray,"INaxiosthis.ltparray");

            }
            axiosthis.ltpGetdata()
           
            axiosthis.intervalId = setInterval(() => {
              axiosthis.ltpGetdata() 
                axiosthis.selectedItem2 = true
              }, 5000);
            // console.log(axiosthis.ltparray,"axiosthis.ltparray");
            // Print the sorted array
            // console.log(sortedData, "kjkjkkjshort2");
            // console.log(axiosthis.desserts, "kjkjkkj2");

          } else {
            // 
          }

          const axios = require('axios');
          let data = JSON.stringify({
            "cc": this.actid
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://192.168.5.164:5050/holdingsTag',
            url: `${apiurl}/holdingsTag`,

            headers: {
              'Content-Type': 'application/json',
              'clientid': this.actid,
              'Authorization': this.susertoken
            },
            data: data
          };

          axios.request(config)
            .then((response) => {
              //console.log(JSON.stringify(response.data));
              let inputObject = response.data.tagArr
              let outer = response.data.colorArr

              //console.log(inputObject);
              let arrayofkeys = []
              //   //console.log(Object.keys(object1));
              arrayofkeys = Object.keys(outer);
              for (let i = 0; i < arrayofkeys.length; i++) {
                axiosthis.outputArray.push({ "text": arrayofkeys[i], "color": outer[arrayofkeys[i]] })
              }
              // axiosthis.filteredArray = axiosthis.outputArray

              const outerarr = Array.from(new Set(axiosthis.outputArray.map(JSON.stringify))).map(JSON.parse);
              axiosthis.outputArray = outerarr
              if (axiosthis.outputArray.length > 0) {
                axiosthis.filterrtag.push('All')
              }
              outerarr.forEach(item => {
                axiosthis.filterrtag.push(item.text);
              });

              // axiosthis.filterrtag = outerarr
              //   console.log(axiosthis.outputArray);
              //console.log(axiosthis.outputArray, "kjkjkjj");
              //console.log(axiosthis.outputArray, "key");

              // for (let i = 0; i < keyarr.length; i++) {
              for (let y = 0; y < axiosthis.desserts.length; y++) {
                // if (keyarr[i] === axiosthis.desserts[y].SCRIP_SYMBOL) {
                axiosthis.desserts[y].tags = []
                //console.log("Match found!", axiosthis.desserts[y].SCRIP_SYMBOL);
                let namesy = axiosthis.desserts[y].SCRIP_SYMBOL;
                let pushdata = []
                for (let val in inputObject[namesy]) {
                  //console.log(inputObject[namesy], val)
                  pushdata.push({ "text": inputObject[namesy][val], "color": outer[inputObject[namesy][val]] })

                }
                axiosthis.desserts[y].tags = pushdata
                // }

                // }
              }


              axiosthis.selectedItem2 = true
              axiosthis.filtertagduplicatearr = axiosthis.desserts
              axiosthis.filteredArraytag = axiosthis.desserts
              // console.log(axiosthis.desserts);
              const tags = Array.from(new Set(this.filtertagduplicatearr.flatMap(item => item.tags.map(tag => tag.text))));
              axiosthis.uniqueTags = ['All', ...tags];
              axiosthis.loader = false
              axiosthis.filterseg(axiosthis.filterdata_unpl)
            })
            .catch((error) => {
              console.log(error);
            });
          // axiosthis.loader = false
          axiosthis.loadermobile = false


        })
        .catch((error) => {
          //console.log(error);
          if (error.message == 'Network Error') {
            this.msg = error.message
            axiosthis.loader = false
            axiosthis.alert = true
            setTimeout(() => {
              axiosthis.alert = false
            }, 5000);
            // alert(error.message)
          }
        });
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  mounted() {
        this.cname = localStorage.getItem("cname");
        var res = this.decryptionFunction(localStorage.getItem("profile_data"));
        // console.log('ttttt',JSON.parse(res));
        var resp = JSON.parse(res);
        this.profiledetails = resp.client_data;
        this.mainholdings();
        this.familyaccountcheck();
    this.convertImageToBase64();

  },
}
</script>

<style>
.toolbar .v-toolbar__content {
  padding: 0 !important;
}



.no-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #E5EBEC;
  border-radius: 16px;
}

.cust-appbar {
  background-color: white !important;
  position: fixed !important;
  z-index: 1 !important;
  border-bottom: 1px solid #ECEFF3 !important;
  box-shadow: 0px 1px 15px 0px rgba(44, 44, 45, 0.03), 0px 5px 22px 0px rgba(154, 154, 156, 0.02) !important;
}

.postionchangeopenpostion {
  position: absolute !important;
  top: 25px !important;
  left: 374px !important;
  right: 200px;
}

.color-palette {
  display: flex;
  flex-wrap: wrap;
}

.color-box {
  width: 30px;
  height: 30px;
  margin: 10px;
  padding: 5px;
  border-radius: 30px;
  cursor: pointer;
}

.selected-color {
  margin-top: 20px;
}

.selected-color span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
</style>