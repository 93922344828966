<template>
  <v-app>
    <v-container class="pa-0 pa-sm-3 pa-md-3 pb-0 mt-n4">
      <v-alert v-if="alert" dense type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <div class="pt-16 pa-6 pa-sm-4 pa-md-4 pb-0  d-none d-md-block">

        <v-col cols="12" class="pl-7">
          <v-card class="mt-5 elevation-0 ">
            <v-toolbar class="mt-3 mb-1 px-1  toolbar-contant " elevation="0" color="white">
              <v-list class="" style="background-color: transparent;">
                <v-list-item class=" pt-0 px-0 ">
                  <v-list-item-content class=" pt-0">
                    <v-list-item-title class="text-start"
                      style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                      Event calendar </v-list-item-title>
                    <v-list-item-subtitle class="text-start pt-1"
                      style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Upcoming events at a glance.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-list class="mr-7 pt-0" style="background-color: transparent;">
                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                  <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
                  <!-- height="16px" /> -->
                  <!-- <v-list-item-content class="pl-2 pa-0">
                    <v-list-item-title class="text-start "
                      style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                      {{ desserts.length }}
                      Trades</v-list-item-title>
                  </v-list-item-content> -->
                </v-list-item>
              </v-list>
              <!-- <v-menu v-if="desserts.length > 0" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                    <v-icon size="20">mdi mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                    <v-list-item-title @click="valdwl(item.title)">{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
              <!-- prepend-inner-icon="mdi-filter-variant" -->

              <!-- <v-menu class="" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat v-model="fromshow"
                    label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :max="maxval" color="black" v-model="fromdate" @input="dateinsert()"></v-date-picker>
                <v-date-picker :max="maxval" color="black" v-model="todate" @change="gettradedata()"></v-date-picker>
              </v-menu> -->
              <!-- <v-select :items="filterr" @change="filterseg(filterdata_unpl)" v-model="filterdata_unpl"
                placeholder="Filter" dense solo rounded flat background-color="#F1F3F8" hide-details
                class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
              </v-select> -->
              <v-text-field style="max-width: 190px;" v-model="search" class="tophundraedmutual mt-4  " height="36px"
                background-color="#F1F3F8" label="Search" solo flat rounded dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>

            </v-toolbar>
            <v-divider></v-divider>
            <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
              <v-tab v-for="item in itemstab" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs> -->

            <!-- <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table ref="dataTableRef" :search="search" mobile-breakpoint hide-default-footer fixed-header
                  disable-sort height="420px" :headers="Overviewheaders" :items="desserts" :loading="loader"
                  :items-per-page="itemperpage" style="border: 1px solid #EFEEF3;" class="elevation-0 rounded-lg mt-6">
                 
                  <template v-slot:[`item.SCRIP_NAME`]="{ item }">
                    <span class="font-weight-regular subtitle-2 mt-2"> {{ item.SCRIP_NAME }} </span><span class="caption"
                      v-if="item.STRIKE_PRICE != 0"> {{ (item.STRIKE_PRICE) }}</span> <span class="caption">{{
                        (item.OPTION_TYPE) }}</span>
                    <span v-if="item.showseries != 'EQ'" class="caption mb-0 font-weight-regular pl-2">{{ item.EXPIRY_DATE
                    }}</span>
                  </template>
                  <template v-slot:[`item.COMPANY_CODE`]="{ item }">
                    <span class="font-weight-regular subtitle-2"> {{ item.COMPANY_CODE }}</span>
                  </template>
                  <template v-slot:[`item.TRADE_DATE`]="{ item }">
                    <span class="font-weight-bold subtitle-2"> {{ item.TRADE_DATE }}</span>
                  </template>
                  <template v-slot:[`item.showamt`]="{ item }">
                    <span class="font-weight-regular">₹{{ item.showamt }}</span>
                  </template>
                  <template v-slot:[`item.TRADE_NUMBER`]="{ item }">
                    <span class="font-weight-regular text-right"> {{ item.TRADE_NUMBER }}</span>
                  </template>
                  <template v-slot:[`item.showtype`]="{ item }">
                    <span v-if="item.showtype == 'BUY'" style="color: green;" class="font-weight-regular">BUY</span>
                    <span v-else-if="item.showtype == 'SELL'" style="color: red;" class="font-weight-regular">
                      SELL</span>
                  </template>
                  <template v-slot:[`item.showprice`]="{ item }">
                    <span class="font-weight-regular" style="text-align:right"> ₹{{ item.showprice }}</span>
                  </template>
                  <template v-slot:[`item.showqnt`]="{ item }">
                    <span class="font-weight-regular"> {{ item.showqnt }}</span>
                  </template>
                  <template v-slot:[`item.STRIKE_PRICE`]="{ item }">
                    <span class="font-weight-regular"> ₹{{ item.STRIKE_PRICE }}</span>
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="20%" height="" />
                      <h4 style="color: rgb(165, 165, 165)">No data available</h4>
                    </v-col>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table hide-default-footer :headers="dessertHeaders" :items="dessertstabs"
                  :single-expand="singleExpand" :expanded.sync="expanded" item-key="name" show-expand class="elevation-1">
                
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      More info about {{ item.name }}
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items> -->
            <v-row>
              <v-col cols="12">
                <v-tabs next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
 v-model="tab" background-color="transparent" color="basil" grow>
                  <v-tab @click="search = ''">
                    Board Meeting
                  </v-tab>
                  <v-tab @click="search = ''">
                    AGM / EGMs
                  </v-tab>


                  <v-tab @click="search = ''">
                    Bonus
                  </v-tab>

                  <v-tab @click="search = ''">
                    Dividend
                  </v-tab>
                  <v-tab @click="search = ''">
                    Rights
                  </v-tab>
                  <v-tab @click="search = ''">
                    Split
                  </v-tab>

                </v-tabs>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table fixed-header disable-sort :headers="boadheader" :items="boardarray" height="495px"
                  hide-default-footer :items-per-page="itemperpageforboard" :loading="loader" :search="search"
                  style="border: 1px solid #efeef3; " class="elevation-0 rounded-lg">
                  <template v-slot:[`item.companyname`]="{ item }">
                      <span class="font-weight-bold" style="text-align: right">
                        {{ item['company name'] }}</span>
                        
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">
                        No data available
                      </p>
                    </v-col>
                  </template>

                </v-data-table>

              </v-tab-item>
              <v-tab-item>
                <v-data-table fixed-header disable-sort :headers="annoseheader" :items="annostablearray" height="495px"
                  hide-default-footer :items-per-page="itemperpageforanons" :loading="loader" :search="search"
                  style="border: 1px solid #efeef3; " class="elevation-0 rounded-lg">
                  <template v-slot:[`item.companyname`]="{ item }">
                      <span class="font-weight-bold" style="text-align: right">
                        {{ item['company name'] }}</span>
                        
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">
                        No data available
                      </p>
                    </v-col>
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    <span v-if="item['AGM date']
" class="font-weight-regular" style="text-align: right">
                      {{ item["AGM date"] }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                      {{ item["EGM date"] }}</span>
                  </template>
                  <template v-slot:[`item.comname`]="{ item }">
                    <span class="font-weight-regular" style="text-align: right">
                      {{ item["company name"] }}</span>
                  </template>
                  <template v-slot:[`item.agenda`]="{ item }">
                    <span class="font-weight-regular" style="text-align: right">
                      {{ item["agenda"] }}</span>
                  </template>
                </v-data-table>

              </v-tab-item>
              <v-tab-item>
                <v-data-table fixed-header disable-sort :headers="bonusheader" :items="bonustablearray" height="495px"
                  hide-default-footer :items-per-page="itemperpageforbonus" :loading="loader" :search="search"
                  style="border: 1px solid #efeef3; " class="elevation-0 rounded-lg">
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">
                        No data available
                      </p>
                    </v-col>
                  </template>
                  <template v-slot:[`item.companyname`]="{ item }">
                      <span class="font-weight-bold" style="text-align: right">
                        {{ item['company name'] }}</span>
                        
                  </template>
                  <template v-slot:[`item.ratio`]="{ item }">
                      <span class="" style="text-align: right">
                        {{ (item.ratio_n).split(".")[0]  }} : {{(item.ratio_d).split(".")[0]}}</span>
                  </template>
                </v-data-table>

              </v-tab-item>
              <v-tab-item>
                <v-data-table fixed-header disable-sort :headers="dividendheader" :items="dividendtablearray"
                  height="495px" hide-default-footer :items-per-page="itemperpagefordivi" :loading="loader"
                  :search="search" style="border: 1px solid #efeef3; " class="elevation-0 rounded-lg">
                  <template v-slot:[`item.recdatediv`]="{ item }">
                      <span v-if="item['record date'] != ''" class="font-weight-regular" style="text-align: right">
                        {{ item['record date'] }}</span>
                        <span v-else class="font-weight-regular" style="text-align: right">
                        -</span>
                  </template>
                  <template v-slot:[`item.companyname`]="{ item }">
                      <span v-if="item['company name'] != ''" class="font-weight-bold" style="text-align: right">
                        {{ item['company name'] }}</span>
                        <span v-else class="font-weight-bold" style="text-align: right">
                        -</span>
                  </template>
                  <template v-slot:[`item.dividenddate`]="{ item }">
                      <span v-if="item['dividend date'] != ''" class="font-weight-regular" style="text-align: right">
                        {{ item['dividend date'] }}</span>
                        <span v-else class="font-weight-regular" style="text-align: right">
                        -</span>
                  </template>
                  <template v-slot:[`item.dividendpershare`]="{ item }">
                      <span v-if="item['dividendpershare'] != ''" class="font-weight-regular" style="text-align: right">
                        {{ item['dividendpershare'] }}</span>
                        <span v-else class="font-weight-regular" style="text-align: right">
                        -</span>
                  </template>
                  <template v-slot:[`item.ex-date`]="{ item }">
                      <span v-if="item['ex-date'] != ''" class="font-weight-regular" style="text-align: right">
                        {{ item['ex-date'] }}</span>
                        <span v-else class="font-weight-regular" style="text-align: right">
                        -</span>
                  </template>
                  
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">
                        No data available
                      </p>
                    </v-col>
                  </template>

                </v-data-table>

              </v-tab-item>
              <v-tab-item>
                <v-data-table fixed-header disable-sort :headers="rightsheader" :items="rightstablearray" height="495px"
                  hide-default-footer :items-per-page="itemperpageforright" :loading="loader" :search="search"
                  style="border: 1px solid #efeef3; " class="elevation-0 rounded-lg">
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">
                        No data available
                      </p>
                    </v-col>
                  </template>
                  <template v-slot:[`item.companyname`]="{ item }">
                      <span class="font-weight-bold" style="text-align: right">
                        {{ item['company name'] }}</span>
                        
                  </template>
                  <template v-slot:[`item.ratio`]="{ item }">
                      <span class="" style="text-align: right">
                         {{ (item.ratio_n).split(".")[0]  }} : {{(item.ration_d).split(".")[0]}}</span>
                        
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table fixed-header disable-sort :headers="splitheader" :items="splittablearray" height="495px"
                  hide-default-footer :items-per-page="itemperpageforanons" :loading="loader" :search="search"
                  style="border: 1px solid #efeef3; " class="elevation-0 rounded-lg">
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15 mt-5">
                      <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                      <p class="font-weight-bold black--text">
                        No data available
                      </p>
                    </v-col>
                  </template>
                  <template v-slot:[`item.companyname`]="{ item }">
                      <span class="font-weight-bold" style="text-align: right">
                        {{ item['company name'] }}</span>
                        
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <div class="mt-2 mt-sm-0 mt-md-0">
          <div class=" mb-4">
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent;">
            <v-list-item class=" pt-0 px-0">
              <v-list-item-content class=" pt-0">
                <v-list-item-title class="text-start"
                  style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                  Event calendar </v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                  style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Upcoming events at a glance.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-list class=" pt-0" style="background-color: transparent;">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
            </v-list-item>
          </v-list>
        </v-toolbar>
        <!-- <v-divider class=" mx-2"></v-divider>
        <div class="d-flex row">
          <p class="text-start  mb-4  mt-5 ml-2 px-4 "
            style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
            No of trades
            <span class=" pa-1 rounded-lg" style=" color: #000000;">{{ (desserts.length) }}</span>
          </p>
        </div> -->
        <v-divider class=" mx-2"></v-divider>
        <v-row class="px-2">
              <v-col cols="12">
                <v-tabs next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
 v-model="tab" background-color="transparent" color="basil" grow>
                  <v-tab>
                    Board Meeting
                  </v-tab>
                  <v-tab>
                    AGM / EGMs
                  </v-tab>
                  <v-tab>
                    Bonus
                  </v-tab>
                  <v-tab>
                    Dividend
                  </v-tab>
                  <v-tab>
                    Rights
                  </v-tab>
                  <v-tab>
                    Split
                  </v-tab>
                </v-tabs>
              </v-col>
          <v-col cols="5">
            <v-text-field v-if="desserts.length > 1" style="width: 100%;" v-model="search"
              class="tophundraedmutual   mt-3  " height="24px" background-color="#F1F3F8" label="Search" solo text-align
              flat rounded dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="16px" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card v-if="boardarray.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in boardarray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
          class="elevation-0  px-4" width="100%">
          <div class="d-flex row pa-4">
            <p class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ item['company name'] }}
            </p>
            <p class="ml-auto mb-0" style="color: #000000; font-size: 15px; font-weight: 459;">
                {{ item['board meeting date'] }}
            </p>
          </div>

          <v-divider class="mb-2"></v-divider>
          <v-row class="px-2">
            <v-col cols="12">

              <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item['agenda'] }}
                

              </p>
              


            </v-col>
            
          </v-row>
        </v-card>
          </v-tab-item>
         <v-tab-item>
            <v-card v-if="annostablearray.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in annostablearray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
          class="elevation-0  px-4" width="100%">
          <div class="d-flex row pa-4">
            <p class="text-start  mb-0" style="color: #000000;font-size: 15px;font-weight:459;"> {{ item['company name'] }}
            </p>
            <p class="ml-auto mb-0" style="color: #000000; font-size: 15px; font-weight: 459;">
                {{ item['date'] }}
            </p>
          </div>

          <v-divider class="mb-2"></v-divider>
          <v-row class="px-2">
            <v-col cols="12">

              <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">{{ item['agenda'] }}
                

              </p>
              


            </v-col>
            
          </v-row>
        </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card v-if="bonustablearray.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in bonustablearray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col>
                                    <p class="text-start pt-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                        {{ item['company name'] }} <span class="caption"> {{ item.source_date }}</span>
                                    </p>
                                </v-col>
                                <v-col class="text-end">
                                    
                                    <p class="text-end pt-2 mb-0"
                                        style="font-size: 15px;font-weight:459;"> {{ (item.ratio_n).split(".")[0]  }} : {{(item.ratio_d).split(".")[0]}}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-2"></v-divider>
                            <v-row class="px-2">
                                <v-col cols="6">
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Ex-Bonus date :<span>{{ item.ex_bonus_date }}</span></p>
                                </v-col>
                                <v-col class="text-end" cols="6">
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Record date :
                                        <span>{{ item.record_date }}</span>
                                    </p>
                                </v-col>
                            </v-row>
                            


                        </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card v-if="dividendtablearray.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in dividendtablearray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col>
                                    <p class="text-start pt-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                        {{ item['company name'] }} <span class="caption"> {{ item['dividend date'] }}</span><span class="caption"> ({{ item['details'] }})</span>
                                    </p>
                                </v-col>
                                <v-col class="text-end">
                                    
                                    <p class="text-end pt-2 mb-0"
                                        style="font-size: 15px;font-weight:459;">{{ item.dividendpershare }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-2"></v-divider>
                            <v-row class="px-2">
                                <v-col cols="6">
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Ex-Dividend date :<span>{{ item['ex-date'] }}</span></p>
                                </v-col>
                                <v-col class="text-end" cols="6">
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Record date :
                                        <span>{{ item['record date'] }}</span>
                                    </p>
                                </v-col>
                            </v-row>
                            


                        </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card v-if="rightstablearray.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in rightstablearray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col>
                                    <p class="text-start pt-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                        {{ item['company name'] }} 
                                    </p>
                                </v-col>
                                <v-col class="text-end">
                                    
                                    <p class="text-end pt-2 mb-0"
                                        style="font-size: 15px;font-weight:459;">{{ item['source date'] }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-2"></v-divider>
                            <v-row class="px-2">
                                <v-col cols="6">
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Rights ratio :<span> {{ (item.ratio_n).split(".")[0]  }} : {{(item.ration_d).split(".")[0]}}</span></p>
                                </v-col>
                                <v-col class="text-end" cols="6">
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Premium : {{ item['premium_rs'] }}
                                        
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row class="px-2 mt-n8">
                                <v-col cols="6">
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Ex-Dividend date :<span>{{ item['ex_rights_date'] }}</span></p>
                                </v-col>
                                <v-col class="text-end" cols="6">
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Record date :
                                        <span>{{ item['record date'] }}</span>
                                    </p>
                                </v-col>
                            </v-row>
                            


                        </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card v-if="splittablearray.length == 0 && loader == false"
          style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
          width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
          class="elevation-0  px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-for="item in splittablearray " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col>
                                    <p class="text-start pt-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                        {{ item['company name'] }} 
                                    </p>
                                </v-col>
                                <v-col class="text-end">
                                    
                                    <p class="text-end pt-2 mb-0"
                                        style="font-size: 15px;font-weight:459;">{{ item['ex_date'] }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-2"></v-divider>
                            <v-row class="px-2">
                                <v-col cols="6">
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Old FV :<span>{{ item['fv_change_from'] }}</span></p>
                                </v-col>
                                <v-col class="text-end" cols="6">
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">New FV : {{ item['fv_change_to'] }}
                                        
                                    </p>
                                </v-col>
                            </v-row>
                           
                            


                        </v-card>
          </v-tab-item>
        </v-tabs-items>
        

      </div>
    </v-container>


  </v-app>
</template>

<script>
// import CalenDartb from '../../heatmap calendars/CalenDartb.vue';
import { apiurl } from '../../../Api.js'
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"


export default {
  data() {

    return {
      annoseheader: [
        {
          text: "Company name",
          value: "companyname",
          align: "start",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Date",
          value: "date",
          align: "center",
          width: "10%",
          class: "headerfont",
        },
        // {
        //   text: "Purpose",
        //   value: "agenda",
        //   align: "start",
        //   class: "headerfont",
        //   width: "10%",
        // },
        // {
        //   text: "start",
        //   value: "agenda",
        //   align: "start",
        //   class: "headerfont",
        //   width: "10%",
        // },{
        //   text: "End",
        //   value: "agenda",
        //   align: "start",
        //   class: "headerfont",
        //   width: "10%",
        // },
        {
          text: "Agenda",
          value: "agenda",
          align: "start",
          class: "headerfont",
          width: "10%",
        },

      ],
      boadheader: [
        
        {
          text: "Company name",
          value: "companyname",
          align: "start",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Date",
          value: "board meeting date",
          width: "10%",
          class: "headerfont",
          align: "center",

        },
        // {
        //   text: "Purpose",
        //   value: "agenda",
        //   align: "start",
        //   class: "headerfont",
        //   width: "10%",
        // },
        // {
        //   text: "start",
        //   value: "agenda",
        //   align: "start",
        //   class: "headerfont",
        //   width: "10%",
        // },{
        //   text: "End",
        //   value: "agenda",
        //   align: "start",
        //   class: "headerfont",
        //   width: "10%",
        // },
        {
          text: "Agenda",
          value: "agenda",
          align: "start",
          class: "headerfont",
          width: "10%",
        },

      ],
      bonusheader: [
        {
          text: "Company name",
          value: "companyname",
          align: "start",
          class: "headerfont",
          width: "10%",
        },


        // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },


        {
          text: "Bonus ratio",
          value: "ratio",
          align: "right",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'Buy Amt', value: 'BAMT', align: "end" },
        
        {
          text: "Announcement date",
          value: "source_date",
          align: "center",
          width: "10%",
          class: "headerfont",
        },
        {
          text: "Record date",
          value: "record_date",
          align: "center",
          width: "10%",
          class: "headerfont",
        },
        {
          text: "Ex-Bonus date",
          value: "ex_bonus_date",
          align: "center",
          width: "10%",
          class: "headerfont",
        },

      ],
      dividendheader: [


        // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },

        {
          text: "Company name",
          value: "companyname",
          align: "start",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Dividend type",
          value: "details",
          align: "start",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Dividendpershare",
          value: "dividendpershare",
          align: "right",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'Buy Amt', value: 'BAMT', align: "end" },
        {
          text: "Announcement date",
          value: "dividenddate",
          width: "10%",
          class: "headerfont",
          align: "center",

        }, {
          text: "Record date",
          value: "recdatediv",
          width: "10%",
          class: "headerfont",
          align: "center",

        }, {
          text: "Ex-Dividend date",
          value: "ex-date",
          width: "10%",
          align: "center",

          class: "headerfont",
        },

      ],
      rightsheader: [

        {
          text: "Company name",
          value: "companyname",
          align: "start",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Rights Ratio",
          value: "ratio",
          align: "right",
          class: "headerfont",
          width: "10%",
        },
        // {
        //   text: "FV",
        //   value: "premium_rs",
        //   align: "right",

        //   class: "headerfont",
        //   width: "10%",
        // },
        // { text: 'Buy Amt', value: 'BAMT', align: "end" },
        {
          text: "Premium",
          value: "premium_rs",
          align: "right",

          class: "headerfont",
          width: "10%",
        },
        {
          text: "Announcement date",
          value: "source date",
          align: "center",
          width: "10%",
          class: "headerfont",
        }, {
          text: "Record date",
          value: "record date",
          align: "center",
          width: "10%",
          class: "headerfont",
        }, {
          text: "Ex-Rights date",
          value: "ex_rights_date",
          align: "center",
          width: "10%",
          class: "headerfont",
        },
      ],
      splitheader: [


        // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },

        {
          text: "Company name",
          value: "companyname",
          align: "start",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Old FV",
          value: "fv_change_from",
          align: "right",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "New FV",
          value: "fv_change_to",
          align: "right",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Split Date",
          value: "ex_date",
          width: "10%",          align: "center",

          class: "headerfont",
        },
      ],
      annostablearray: [],
      rightstablearray: [],
      dividendtablearray: [],
      bonustablearray: [],
      splittablearray: [],
      expanded: [],
      itemperpageforanons: 0,
      itemperpageforbonus: 0,
      itemperpagefordivi: 0,
      itemperpageforright: 0,
      itemperpageforsplit: 0,
      itemperpageforboard : 0,
            boardarray : [],
      singleExpand: true,
      // tab: null,
      radios: null,
      sheet: false,
      filterr: [
        "All", "Equities", "Future & Options", "Commodities", "Currencies"
      ],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 600000)).toISOString().substr(0, 10),
      menu2: false,
      tab: null,

      menu23: false,
      menu1: false,
      dates: [''],
      fromdate: '',
      loader: false,
      todate: '',
      Series: '',
      search: '',
      more: true,
      less: false,
      itemperpage: 0,
      offset: '',
      fromshow: '',
      itemsdwl: [
        {
          title: 'Download Excel',
        },
        {
          title: 'Download Pdf',
        },
      ],
      msg: '',
      maxval: '',
      firstmax: '',

      filterdata_unpl: "All",
      Overviewheaders: [
        { text: "Trade Date", value: "TRADE_DATE", align: 'end', sortable: true, class: "headerfont" },

        { text: "Exchange", value: "COMPANY_CODE", align: 'end', class: "headerfont" },

        {
          text: "Scrip", value: "SCRIP_NAME", align: 'start', class: "headerfont"
        },

        { text: "Trade Type", value: "showtype", align: 'center', class: "headerfont" },
        { text: "Quantity", value: "showqnt", align: 'end', class: "headerfont" },
        { text: "Price", value: "showprice", align: 'end', class: "headerfont" },
        { text: "Amount", value: "showamt", align: 'end', class: "headerfont" },

        { text: "Trade No", value: "TRADE_NUMBER", align: 'end', class: "headerfont" },

      ],
      desserts: [],
      alert: false,


    };
  },
  methods: {

    scrollToBottom() {
      const dataTable = this.$refs.dataTableRef;

      if (dataTable) {
        setTimeout(() => {
          const container = dataTable.$el.querySelector('.v-data-table__wrapper');
          container.scrollTop = container.scrollHeight;

          // if (container.scrollTop) {
          //   alert('page go to end')
          // }
        }, 0);
      }
    },
    valdwl(item) {
      let data = item
      // console.log("dsdsdsdsds", data);

      if (data == "Download Excel") {

        const dada = this.desserts;
        // let dada = this.desserts23;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        dada.forEach(function (v) {
          delete v.USER_ID
            ;
          delete v.TRADE_TYPE
            ;
          // delete v.NSE_SCRIPCODE;
          delete v.TRADE_TIME
            ;
          delete v.SERIES;
          delete v.SCRIP_SERIES
          delete v.ISIN
          delete v.CLIENT_CODE

          delete v.ORDER_TIME
          delete v.ORDER_NUMBER
            ;
        });
        const exportType = exportFromJSON.types.csv;
        const fileName = `${'TradeBook_'}${this.fromshow}`
        if (dada) exportFromJSON({ data: dada, fileName, exportType });
      } else if (data == "Download Pdf") {

        let rows = [];
        let columnHeader = ['Trade Date', 'Exchange', 'Scrip', 'Trade Type', 'Quantity', 'Price', 'Amount', 'Trade No'];
        let pdfName = `${'TradeBook_'}${this.fromshow}`
        // doc.text(`Ledger_${this.yearis}`, 10, 10);
        // doc.text('Zebu', 10 ,10)
        this.desserts.forEach(element => {
          var temp = [
            element.TRADE_DATE, element.COMPANY_CODE, element.SCRIP_NAME, element.showtype, element.showqnt, element.showprice, element.showamt, element.TRADE_NUMBER
          ];
          rows.push(temp);
        });
        var doc = new jsPDF();
        autoTable(doc, { head: [columnHeader], body: rows, });
        doc.save(pdfName + '.pdf');
      }


      //pdf


    },
    lessbtn() {
      this.more = true
      this.less = false
      this.itemperpage = 9
    },
    morebtn() {
      this.more = false
      this.less = true
      this.itemperpage = this.desserts.length
    },

    dateinsert() {
      //console.log('kjj')
      var fromdate = ''
      // var todate = ''
      let dateString = this.fromdate
      let dateObject = new Date(dateString);
      let year = dateObject.getFullYear();
      let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
      let day = dateObject.getDate();
      let formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
      fromdate = formattedDate
      this.fromshow = fromdate
    },
    gettradedata() {
      this.desserts = []
      this.menu2 = false
      this.menu23 = false

      if (this.fromdate != '' && this.todate != '') {


        this.loader = true
        var fromdate = ''
        var todate = ''
        let dateString = this.fromdate
        let dateObject = new Date(dateString);
        let year = dateObject.getFullYear();
        let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
        let day = dateObject.getDate();
        let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
        fromdate = formattedDate
        this.fromshow = fromdate
        let dateString1 = this.todate
        let dateObject1 = new Date(dateString1);
        let year1 = dateObject1.getFullYear();
        let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
        let day1 = dateObject1.getDate();
        let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
        todate = formattedDate1
        this.toshow = todate
        this.fromshow = this.fromshow + '_to_' + this.toshow

        const axios = require('axios');
        let data = JSON.stringify({
          "cc": this.actid,
          "from": fromdate,
          "to": todate,
        });
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${apiurl}/getTradeDetails`,

          headers: {
            'Content-Type': 'application/json',
            'clientid': this.actid,
            'Authorization': this.susertoken
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            //console.log(JSON.stringify(response.data));
            axiosthis.loader = false

            axiosthis.desserts = response.data['trades']
            axiosthis.itemperpage = axiosthis.desserts.length

            for (let i = 0; i < axiosthis.desserts.length; i++) {
              let dateString = axiosthis.desserts[i]['EXPIRY_DATE'];
              let date = new Date(dateString);
              let year = date.getFullYear();
              let month = date.getMonth() + 1; // Months are 0-based, so add 1
              let day = date.getDate();
              let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
              axiosthis.desserts[i]['EXPIRY_DATE'] = numericalDateFormat
            }
            //console.log(axiosthis.desserts, "axiosthis.desserts2");
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              let dateString = axiosthis.desserts[i]['TRADE_DATE'];
              let date = new Date(dateString);
              let year = date.getFullYear();
              let month = date.getMonth() + 1; // Months are 0-based, so add 1
              let day = date.getDate();
              let numericalDateFormat = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} `;
              axiosthis.desserts[i]['TRADE_DATE'] = numericalDateFormat
            }
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              if (axiosthis.desserts[i]['BUY_QUANTITY'] != 0) {
                axiosthis.desserts[i]['showqnt'] = axiosthis.desserts[i]['BUY_QUANTITY']
                axiosthis.desserts[i]['showprice'] = axiosthis.desserts[i]['BUY_PRICE']
                axiosthis.desserts[i]['showtype'] = "BUY"
              } else if (axiosthis.desserts[i]['SELL_QUANTITY'] != 0) {
                axiosthis.desserts[i]['showqnt'] = axiosthis.desserts[i]['SELL_QUANTITY']
                axiosthis.desserts[i]['showprice'] = axiosthis.desserts[i]['SELL_PRICE']
                axiosthis.desserts[i]['showtype'] = "SELL"
              }
            }
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              let showamt = axiosthis.desserts[i]['showqnt'] * axiosthis.desserts[i]['showprice']
              axiosthis.desserts[i]['showamt'] = showamt.toFixed(2)
            }
            // //console.log(axiosthis.desserts[10]['SERIES'],"axiosthis.desserts[i]['SERIES']");
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              if (axiosthis.desserts[i]['SERIES'] == "EQ") {
                // //console.log(axiosthis.desserts[i]['SERIES'],"axiosthis.desserts[i]['SERIES']");
                axiosthis.desserts[i]['showseries'] = 'EQ'
              } else {
                axiosthis.desserts[i]['showseries'] = 'FNO'
              }
            }
            axiosthis.dessertspop = axiosthis.desserts
            // console.log("dedededede", axiosthis.dessertspop);
            axiosthis.scrollToBottom()

          })
          .catch((error) => {
            console.log(error);
            if (error.message == 'Network Error') {
              this.msg = error.message
              axiosthis.loader = false
              axiosthis.alert = true
              setTimeout(() => {
                axiosthis.alert = false
              }, 5000);
              // alert(error.message)
            }
          });
      } else {
        // //console.log("please enter the date")
      }

    },
  },
  // components: { CalenDartb },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    filteredDesserts() {
      const searchTerm = this.search.toLowerCase();
      return this.desserts.filter(item => item.SCRIP_NAME.toLowerCase().includes(searchTerm));
    },
  },
  mounted() {
    var today = new Date();
    // console.log((today.getDate() - 1),"date");

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    // var todayapi = dd + '/' + mm + '/' + yyyy;
    var today1 = dd + '/' + mm + '/' + yyyy;
    var fromapi = '01' + '/' + mm + '/' + yyyy;
    this.maxval = yyyy + '-' + mm + '-' + dd
    let ydd = String(today.getDate() - 1);
    let ymm = String(today.getDate()).padStart(2, '0');
    let yeyy = String(today.getMonth() + 1).padStart(2, '0');
    this.firstmax = yeyy + '-' + ymm + '-' + ydd
    this.fromshow = fromapi
    this.fromdate = yyyy + '-' + mm + '-' + '01';
    // var  =yyyy  + '/' + dd + '/' + mm
    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    this.loader = true

    this.toshow = today1
    this.fromshow = this.fromshow + '_to_' + this.toshow
    let axiosthis = this
    const axios = require('axios');
    let data = '';

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://v3.mynt.in/equity/getEquityCorporateActions',
      
      // url: `${apiurl}/getTradeDetails`,

      headers: {},
      data: data
    };

    axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // Function to process date arrays
        // Convert date strings and format them
        
        // function processDateChnArray(array, datePropertyNames) {
        //     array.forEach(item => {
        //     datePropertyNames.forEach(datePropertyName => {
        //       const dateValue = item[datePropertyName];

        //       if (dateValue instanceof Date && !isNaN(dateValue) && dateValue !== '') {
        //       item[datePropertyName] = formatDate(dateValue);
        //       } else if (typeof dateValue === 'string' && dateValue !== '') {
        //         const [year, month, day] = dateValue.split('-');
        //         item[datePropertyName] = `${day}/${month}/${year}`;
        //       }

        //     });
        //   });
        // }
        // function formatDate(date) {
        //   const day = date.getDate().toString().padStart(2, '0');
        //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
        //   const year = date.getFullYear();
        //   return `${day}/${month}/${year}`;
        // }
        // if (response.data['AGM/EGM'].length != 0) {
        //   processDateChnArray(response.data['AGM/EGM'], ['AGM/EGM']);
        //   processDateChnArray(response.data['AGM/EGM'], ['EGM date']);
        // }
        // if (response.data.boardmeeting.length != 0) {
        //   processDateChnArray(response.data.boardmeeting, ['board meeting date']);
        // }
        // if (response.data.dividend.length != 0) {
        //   processDateChnArray(response.data.dividend, ['ex-date', 'record date','dividend date']);
        // }
        // if (response.data.bonus.length != 0) {
        //   processDateChnArray(response.data.bonus, ['source_date', 'record_date','ex_bonus_date']);
        // }
        // if (response.data.rights.length != 0) {
        //   processDateChnArray(response.data.rights, ['source date', 'record date','ex_rights_date']);
        // }
        // if (response.data.split.length != 0) {
        //   processDateChnArray(response.data.split, ['ex_date']);
        // }


        // response.data.dividend.sort((a, b) => {
        //     let dateA = new Date(b['ex-date'].split('/').reverse().join('/'));
        //     let dateB = new Date(a['ex-date'].split('/').reverse().join('/'));
        //     return dateA - dateB;
        // });
       
        // response.data.bonus.sort((a, b) => {
        //     let dateA = new Date(b['ex_bonus_date'].split('/').reverse().join('/'));
        //     let dateB = new Date(a['ex_bonus_date'].split('/').reverse().join('/'));
        //     return dateA - dateB;
        // });

        // response.data.rights.sort((a, b) => {
        //     let dateA = new Date(b['ex_rights_date'].split('/').reverse().join('/'));
        //     let dateB = new Date(a['ex_rights_date'].split('/').reverse().join('/'));
        //     return dateA - dateB;
        // });
        
        // response.data.split.sort((a, b) => {
        //     let dateA = new Date(b['ex_date'].split('/').reverse().join('/'));
        //     let dateB = new Date(a['ex_date'].split('/').reverse().join('/'));
        //     return dateA - dateB;
        // });
        // // if (response.data.boardmeeting.length != 0) {
        // //   processDateChnArray(response.data.boardmeeting, ['board meeting date']);
        // // }

        axiosthis.boardarray = response.data['boardmeeting']
        axiosthis.itemperpageforboard = axiosthis.boardarray.length

        axiosthis.annostablearray = response.data['AGM/EGM']
        axiosthis.itemperpageforanons = axiosthis.annostablearray.length
        // console.log(axiosthis.annostablearray,"siriri");

        axiosthis.rightstablearray = response.data['rights']
        axiosthis.itemperpageforright = axiosthis.rightstablearray.length
        axiosthis.dividendtablearray = response.data.dividend
        axiosthis.itemperpagefordivi = axiosthis.dividendtablearray.length

        axiosthis.bonustablearray = response.data.bonus
        axiosthis.itemperpageforbonus = axiosthis.bonustablearray.length

        axiosthis.splittablearray = response.data.split
        axiosthis.itemperpageforsplit = axiosthis.splittablearray.length

        axiosthis.loader = false

      })
      .catch((error) => {
        console.log(error);
        if (error.message == 'Network Error') {
          this.msg = error.message
          axiosthis.txtloader = false
          axiosthis.alert = true
          setTimeout(() => {
            axiosthis.alert = false
          }, 5000);
        }
      });

  },
}
</script>

<style>
.v-picker__title.White {
  display: none;
}

.v-picker__title.black {
  display: none;
}
</style>